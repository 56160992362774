<app-stepper-page-template
  [title]="'onboarding.languages.title'"
  [description]="'onboarding.languages.stepDescription'"
  [progressTitle]="'onboarding.languages.progressTitle'"
  [numberOfSteps]="7"
  [step]="5"
>
  <p class="mt-4" translate>onboarding.languages.noExtraCost</p>
  <div class="mt-6 flex flex-row flex-wrap w-full justify-center gap-5">
    <button class="btn btn-primary min-w-36" (click)="no()" translate>
      general.no
    </button>
    <button class="btn btn-primary min-w-36" (click)="yes()" translate>
      general.yes
    </button>
  </div>
</app-stepper-page-template>
