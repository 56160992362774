import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupabaseService } from '@app/shared/supabase.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly supabase: SupabaseService) {}

  async username(): Promise<string> {
    return await this.supabase.getSession2().then((s) => {
      return s.user.email;
    });
  }

  async isLoggedIn(): Promise<boolean> {
    const loggedIn = typeof (await this.supabase.getSession2()) !== 'undefined';
    console.debug('isLoggedIn() returns', loggedIn);
    return loggedIn;
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    return fromPromise(
      this.supabase.signOut().then((x) => {
        return true;
      })
    );
  }
}
