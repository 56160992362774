<ul class="menu menu-horizontal px-1">
  <li>
    <details>
      <summary>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
          />
        </svg>
      </summary>
      <ul
        class="bg-base-100 rounded-t-none p-2 z-20 w-56 shadow-lg"
        style="
          right: 0px;
          top: 17px;
          border: 1px solid #f1f1f1;
          border-radius: 8px;
        "
      >
        <li
          *ngFor="let language of languagesUserCaresAbout"
          (click)="setLanguage(language.code)"
          [ngClass]="{ active: language.code === currentLang }"
        >
          <button>{{ language.name }}</button>
        </li>
        <li style="height: 10px">
          <div
            style="
              border-top: 1px solid grey;
              border-radius: 0;
              margin-top: 5px;
            "
          ></div>
        </li>
        <li
          *ngFor="let language of languages"
          (click)="setLanguage(language.code)"
          [ngClass]="{ active: language.code === currentLang }"
        >
          <button>{{ language.name | capitalize }}</button>
        </li>
      </ul>
    </details>
  </li>
</ul>
