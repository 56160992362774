import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.scss'],
})
export class DeleteAlertComponent implements OnInit {
  name: string;
  message: string | undefined;

  constructor(
    private dialogRef: DialogRef<DeleteAlertComponent>,
    @Inject(DIALOG_DATA) public data: { name: string; message?: string }
  ) {
    this.name = data.name;
    this.message = data.message;
  }

  ngOnInit(): void {}

  cancel() {
    // @ts-ignore
    this.dialogRef.close(false);
  }

  delete() {
    // @ts-ignore
    this.dialogRef.close(true);
  }
}
