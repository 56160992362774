<div class="onboarding-container">
  <!-- 

    If you want to add a new step to the onboarding process.
    You have to create a new rote in the router outlet, and on submit
    add navigation to the next step and previous step. 

    Why is it solved like this?
    
    Two reason: 
    1. to get a nice navigation process where you can use the 
    back button and the next button to navigate between the steps..
    2. To get titles on each screen that can be defined in the router.

    Disadvantages:
    you have to add a new route for each step, and you have to add the
    current-step etc for each route, and you have to add the navigation for each component.
  -->

  <router-outlet></router-outlet>
</div>
