<div>
  <div class="message">
    <h2 [ngClass]="type" class="title">{{ title | translate }}</h2>
    <pre class="description">{{ description | translate }}</pre>
  </div>
  <div class="actions">
    <button class="btn btn-primary" (click)="onOk()" translate>
      general.ok
    </button>
  </div>
</div>
