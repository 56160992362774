import {
  ElementRef,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import * as confetti from 'canvas-confetti';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

/**
 * Vi skal være et artig system å bruke, så konfetti er bra, men ikke bruk denne for mye rundt om kring 😅
 */
export class ConfettiService {
  private renderer2: Renderer2;
  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  shoot() {
    const canvas = this.renderer2.createElement('canvas');
    this.renderer2.setStyle(canvas, 'position', 'fixed');
    this.renderer2.setStyle(canvas, 'top', '0');
    this.renderer2.setStyle(canvas, 'left', '0');
    this.renderer2.setStyle(canvas, 'width', '100vw');
    this.renderer2.setStyle(canvas, 'height', '100vh');
    this.renderer2.setStyle(canvas, 'z-index', '999999');
    this.renderer2.setStyle(canvas, 'pointer-events', 'none');
    this.renderer2.appendChild(this.document.body, canvas);
    var myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true,
    });
    myConfetti({
      particleCount: 100,
      spread: 160,
    });
  }
}
