import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { ITable } from '@app/shared/SupabaseTypes/ITable';
import { onboardingRouteSteps } from '../onboarding.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { SupabaseService } from '@app/shared/supabase.service';

type AreasWithTables = IArea & { tables: ITable[] };

/**
 * Note!
 * I had two approaches. One was to refetch all tables and areas on each change,
 * this would make sure the view the user see is 100% up to date..
 * It would also make the code much cleaner.
 *
 * The other approach was just to to the post requests and keep the
 * state locally. This make it more snappy, but can result in the data
 * not matching the database if error is not handled well enough.
 *
 * It is also a bit more code. But i went for this approach for now to avoid
 * sending to many requests to the database.
 */
@Component({
  selector: 'app-onboarding-number-of-areas',
  templateUrl: './onboarding-number-of-areas.component.html',
  styleUrls: ['./onboarding-number-of-areas.component.scss'],
})
export class OnboardingNumberOfAreasComponent implements OnInit {
  areas: AreasWithTables[] = [];
  restaurantId: number = -1;
  isLoading = false;

  form = new FormGroup({
    areas: new FormControl<number>(1),
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: Dialog,
    private location: Location,
    private supabase: SupabaseService
  ) {}

  async ngOnInit(): Promise<void> {
    const paramRestaurantId = this.route.snapshot.paramMap.get('restaurant-id');
    this.restaurantId = paramRestaurantId ? +paramRestaurantId : -1;

    let areas = await this.supabase.getAreas(this.restaurantId);
    const skipThisStepAsTheUserHasAlreadyCreatedAreas = areas.length > 0;
    if (skipThisStepAsTheUserHasAlreadyCreatedAreas) {
      this.router.navigate([onboardingRouteSteps.step43, this.restaurantId]);
    }
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step3, this.restaurantId]);
  }

  async next() {
    await this.supabase.updateUsersProgressInOnboardingProcess(
      'multipleAreasDone'
    );

    const navigateToAreaCreator =
      this.form.value.areas && this.form.value.areas > 1;
    if (navigateToAreaCreator) {
      let areaCount = this.form.value.areas ? +this.form.value.areas : -1;
      this.router.navigate([
        onboardingRouteSteps.step42,
        this.restaurantId,
        areaCount,
      ]);
    } else {
      this.router.navigate([onboardingRouteSteps.step43, this.restaurantId]);
    }
  }

  changeAreas(count: number): void {
    let areas = this.form.get('areas');
    if (areas && areas.value && areas.value + count > 0) {
      areas.setValue(areas.value + count);
    }
  }
}
