type weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
const weekdays: weekday[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export function weekdayNumberToDay(weekdayNumber: number): weekday {
  return weekdays[weekdayNumber - 1];
}

export function dayToWekdayNumber(day: weekday): number {
  return weekdays.indexOf(day) + 1;
}
