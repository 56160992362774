<app-stepper-page-template
  [title]="'organizationDetails.onboarding.title'"
  [progressTitle]="'organizationDetails.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="1"
  [image]="'assets/tables.png'"
  [imageAlt]="'organizationDetails.onboarding.imageAlt'"
>
  <p class="mt-6" translate>
    organizationDetails.onboarding.organizationNameDesc
  </p>
  <form (ngSubmit)="submit()" [formGroup]="organizationInfoForm" novalidate>
    <div class="form-control">
      <label class="label">
        <span class="label-text" translate
          >organizationDetails.organizationName</span
        >
      </label>
      <input
        type="text"
        class="input input-bordered"
        formControlName="name"
        autocomplete="organization"
        [placeholder]="
          'organizationDetails.organizationName.placeholder' | translate
        "
        required
      />
      <div
        class="form-control-error"
        *ngIf="
          organizationInfoForm.controls['name'].invalid &&
          organizationInfoForm.controls['name'].touched
        "
      >
        <span translate>organizationDetails.organizationName.required</span>
      </div>
    </div>

    <p class="mt-10" translate>organizationDetails.onboarding.userinfo</p>

    <div class="form-control">
      <label class="label">
        <span class="label-text" translate>organizationDetails.firstname</span>
      </label>
      <input
        type="text"
        class="input input-bordered"
        autocomplete="given-name"
        formControlName="firstname"
        required
      />
      <div
        class="form-control-error"
        *ngIf="
          organizationInfoForm.controls['firstname'].invalid &&
          organizationInfoForm.controls['firstname'].touched
        "
      >
        <span translate>organizationDetails.firstname.required</span>
      </div>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text" translate>organizationDetails.lastname</span>
      </label>
      <input
        type="text"
        class="input input-bordered"
        autocomplete="family-name"
        formControlName="lastname"
        required
      />
      <div
        class="form-control-error"
        *ngIf="
          organizationInfoForm.controls['lastname'].invalid &&
          organizationInfoForm.controls['lastname'].touched
        "
      >
        <span translate>organizationDetails.lastname.required</span>
      </div>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text" translate
          >organizationDetails.phonenumber</span
        >
      </label>
      <ngx-material-intl-tel-input
        [fieldControl]="organizationInfoForm.controls.phonenumber"
        [required]="true"
        [autoIpLookup]="true"
        [hidePhoneIcon]="true"
      >
      </ngx-material-intl-tel-input>
      <div
        class="form-control-error"
        *ngIf="
          organizationInfoForm.controls['phonenumber'].invalid &&
          organizationInfoForm.controls['phonenumber'].touched
        "
      >
        <span translate>organizationDetails.phonenumber.required</span>
      </div>
    </div>

    <div class="form-actions mt-5">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="organizationInfoForm.invalid || isLoading"
      >
        <span *ngIf="!isLoading" translate>general.next</span>
        <app-loader
          [isLoading]="isLoading"
          *ngIf="isLoading"
          class="inline-loader"
        ></app-loader>
      </button>
    </div>
  </form>
</app-stepper-page-template>
