import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Onboarding } from './onboarding.service';
import { OnboardingOrganizationComponent } from './onboarding-organization/onboarding-organization.component';
import { OnboardingRestaurantComponent } from './onboarding-restaurant/onboarding-restaurant.component';
import { OnboardingTablesAndAreasComponent } from './onboarding-tables-and-areas/onboarding-tables-and-areas.component';
import { OnboardingSuccessComponent } from './onboarding-success/onboarding-success.component';
import { OnboardingPaymentComponent } from '@app/onboarding/onboarding-payment/onboarding-payment.component';
import { OnboardingOpeningHoursComponent } from './onboarding-opening-hours/onboarding-opening-hours.component';
import { OnboardingNumberOfAreasComponent } from '@app/onboarding/number-of-areas/onboarding-number-of-areas.component';
import { OnboardingNameOfAreasComponent } from '@app/onboarding/name-of-areas/onboarding-name-of-areas.component';
import { OnboardingRestaurantAddressCityCountryComponent } from '@app/onboarding/onboarding-restaurant-adress/onboarding-restaurant-address-city-country.component';
import { OnboardingMultipleLanguagesComponent } from '@app/onboarding/multiple-languages/onboarding-multiple-languages.component';
import { OnboardingDefaultLanguageComponent } from '@app/onboarding/multiple-languages-default-language/onboarding-default-language.component';
import { OnboardingAddLanguageComponent } from '@app/onboarding/multiple-languages-add-language/onboarding-add-language.component';
import { OnboardingBookingConfirmationComponent } from '@app/onboarding/booking-confirmation/onboarding-booking-confirmation.component';
import { TranslationResolverService } from '@app/onboarding/translation-resolver-service.service';

const routes: Routes = [
  Onboarding.childRoutes([
    { path: '', redirectTo: 'organization', pathMatch: 'full' },
    {
      path: 'organization',
      component: OnboardingOrganizationComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.1' },
    },
    {
      path: 'restaurant',
      component: OnboardingRestaurantComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.2' },
    },
    {
      path: 'restaurant/:restaurant-id',
      component: OnboardingRestaurantComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.2' },
    },
    {
      path: 'restaurant-address/:restaurant-id',
      component: OnboardingRestaurantAddressCityCountryComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.3' },
    },
    {
      path: 'opening-hours/:restaurant-id',
      component: OnboardingOpeningHoursComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.4' },
    },
    {
      path: 'opening-hours',
      component: OnboardingOpeningHoursComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.4' },
    },
    {
      path: 'number-of-areas/:restaurant-id',
      component: OnboardingNumberOfAreasComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.5' },
    },
    {
      path: 'name-of-areas/:restaurant-id/:number-of-areas',
      component: OnboardingNameOfAreasComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.6' },
    },
    {
      path: 'tables-and-areas',
      component: OnboardingTablesAndAreasComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.7' },
    },
    {
      path: 'tables-and-areas/:restaurant-id',
      component: OnboardingTablesAndAreasComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.7' },
    },
    {
      path: 'languages',
      component: OnboardingMultipleLanguagesComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.8' },
    },
    {
      path: 'default-language',
      component: OnboardingDefaultLanguageComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.9' },
    },
    {
      path: 'add-languages',
      component: OnboardingAddLanguageComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.9' },
    },
    {
      path: 'booking-confirmation',
      component: OnboardingBookingConfirmationComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.10' },
    },
    {
      path: 'subscribe',
      component: OnboardingPaymentComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.11' },
    },
    {
      path: 'success-screen',
      component: OnboardingSuccessComponent,
      resolve: { title: TranslationResolverService },
      data: { titleKey: 'onboarding.titles.12' },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutingModule {}
