import { Injectable } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';
import { TranslationMapper } from '@app/shared/SupabaseTypes/translation-mapper';
import * as _ from 'lodash';
import { ITranslation } from '@app/shared/SupabaseTypes/ITranslation';
import { PostgrestResponse } from '@supabase/supabase-js';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private localStorage2: {
    translations: Map<string, ITranslationVM[]>; // restaurantId -> openingHours
  };

  constructor(private supabaseService: SupabaseService) {
    this.localStorage2 = {
      translations: new Map<string, ITranslationVM[]>(),
    };
  }
  async getDefaultLanguage(): Promise<string> {
    return this.supabaseService
      .getTenant()
      .then((tenant) => tenant.defaultLanguage);
  }

  // Bruk denne hvis du har et translations objekt med mange ITranslationVM[] i seg. Forenkler jobbingen med oversettelse ved at man slipper å mappe så mye
  async upsertTranslationsObject(translationsToStore: any): Promise<{}> {
    const translationKeys = Object.keys(translationsToStore);

    const promises = Object.keys(translationsToStore).map((key) => {
      return this.upsertTranslations(translationsToStore[key]);
    });

    // kjører alle i parallell for å spare tid. Bulk-upsert virker ikke som forventet i supabase (bug)
    const results = await Promise.all(promises);

    const translations = {};
    for (let i = 0; i < translationKeys.length; i++) {
      translations[translationKeys[i]] = results[i];
    }

    return translations;
  }

  /**
   * Id som returneres her er ment til å lagres i en annen tabell, for eksempel i opening_hours tabellen.
   */
  async storeTranslationsAndGetTextIdToStoreInDb(
    translationsXX: ITranslationVM[]
  ): Promise<number | null> {
    const groupedByLanguage = _.groupBy(translationsXX, 'language');
    _.forEach(groupedByLanguage, (translations, language) => {
      if (translations.length > 1) {
        throw new Error(
          'Only one translation per language is allowed in this context. Instead of sending all translations, send only the one at the time (and do it in parallel).'
        );
      }
    });

    let textIdToStoreInReferencingTable: null | number = null;
    if (translationsXX !== null && translationsXX.length > 0) {
      const translations = new TranslationMapper().toPersistenceArray(
        translationsXX
      );

      textIdToStoreInReferencingTable =
        await this.upsertTranslationsAndGetTextId(translations);

      return textIdToStoreInReferencingTable;
    }

    return null;
  }

  async upsertTranslationsAndGetTextId(
    translations: ITranslation[]
  ): Promise<number | null> {
    let iTranslations = await this.upsertTranslations(translations);
    if (iTranslations === null || iTranslations.length === 0) {
      return null;
    }
    return iTranslations[0].textId ? iTranslations[0].textId : null;
  }

  /**
   * Upsert translations, but be aware that you can only pass the same text, not many different texts. Ie if you send in a text field you can only send in one text field and it's translations, not many fields at once
   *
   * The method returns the translations for the textId that was inserted or updated
   */
  async upsertTranslations(
    translations: ITranslationVM[] | null
  ): Promise<ITranslationVM[]> {
    if (
      typeof translations === 'undefined' ||
      translations === null ||
      translations.length === 0
    ) {
      return [];
    }

    translations = await this.sortTranslationsAlwaysDefaultLanguageFirst(
      translations
    ); // to ensure that the default language is always first and that we always use it's textId

    console.debug('upsertTranslations', translations);

    const uniqueTextIds = new Set(
      translations.map((translation) => translation.textId)
    );

    if (uniqueTextIds.size > 1) {
      throw new Error(
        'This should not happen as it indicates that you are updating multiple texts at once. This is not supported.'
      );
    }

    const request: ITranslationVM[] = [];

    let result: PostgrestResponse<any> | null = null;

    let generatedOrExistingTextId: number | null = null;

    for (let i = 0; i < translations.length; i++) {
      const translation = translations[i];

      if (
        typeof translation.description === 'undefined' ||
        translation.description === ''
      ) {
        console.warn(
          'You should always add a description to the text. It will greatly help our tenant users and future translators',
          translation
        );
      }

      const isNewTextNotStoredBefore =
        typeof translation.textId === 'undefined' ||
        typeof translation.id === 'undefined';
      const isNewTranslationForExistingTextId =
        typeof translation.textId !== 'undefined' && translation.id === null;
      const shouldUpdateExistingTranslation =
        typeof translation.textId !== 'undefined' && translation.id !== null;
      const shouldSkipEmptyTranslation =
        typeof translation.text === 'undefined' || translation.text === '';
      const shouldDeleteExistingTranslation =
        shouldSkipEmptyTranslation && shouldUpdateExistingTranslation;

      const tmp: any = {
        tenantId: (await this.supabaseService.getTenant()).id,
        textId: generatedOrExistingTextId || translation.textId, // prefer the generated textId if it exists
        language: translation.language,
        text: translation.text,
        description: translation.description,
        id: translation.id ? translation.id : undefined,
        typeOfInput: translation.typeOfInput
          ? translation.typeOfInput
          : 'input',
      };

      if (shouldDeleteExistingTranslation) {
        result = await this.supabaseService
          .getSupabaseNativeClient()
          .from('translations')
          .delete()
          .eq('id', translation.id)
          .eq('textId', translation.textId)
          .select();
      } else if (shouldSkipEmptyTranslation) {
        console.debug('Skipping empty text ', translation);
        continue;
      } else if (isNewTextNotStoredBefore) {
        result = await this.supabaseService
          .getSupabaseNativeClient()
          .from('translations')
          .insert(tmp)
          .select();
      } else if (isNewTranslationForExistingTextId) {
        result = await this.supabaseService
          .getSupabaseNativeClient()
          .from('translations')
          .insert(tmp)
          .select();
      } else if (shouldUpdateExistingTranslation) {
        result = await this.supabaseService
          .getSupabaseNativeClient()
          .from('translations')
          .update(tmp)
          .eq('id', translation.id)
          .eq('textId', translation.textId)
          .select();
      }

      if (result !== null && result.data) {
        generatedOrExistingTextId = result.data[0].textId;
      } else {
        throw new Error('Could not insert translation to database.');
      }
    }

    const textId = result?.data?.pop().textId;

    if (typeof textId === 'undefined') {
      return [];
    }

    return this.getTranslations(textId);
  }

  async sortTranslationsAlwaysDefaultLanguageFirst(
    translations: ITranslation[]
  ): Promise<ITranslation[]> {
    const defaultLanguage = await this.getDefaultLanguage();

    translations.sort((a, b) => {
      if (a.language === defaultLanguage) {
        return -1;
      }
      if (b.language === defaultLanguage) {
        return 1;
      }
      return 0;
    });

    return translations;
  }

  async getAllTranslations(): Promise<ITranslationVM[]> {
    console.debug('SupabaseService.getAllTranslations');

    let languagesInUseByTenant = await this.supabaseService
      .getSupabaseNativeClient()
      .from('tenant_languages')
      .select('languageId');

    let languageArr: string[] = [];
    if (languagesInUseByTenant.data) {
      languageArr = languagesInUseByTenant.data.map((x) => x.languageId);
    }

    const request = this.supabaseService
      .getSupabaseNativeClient()
      .from('translations')
      .select(
        `
      *,
      languages (
        value
      )
      `
      )
      .in('language', languageArr) // fordi vi kun vil ha de språkene som er i bruk av tenant og man kan ha translations lagret selvom man har fjernet et språk (for å kunne gjenopprette språket)
      .order('textId', { ascending: true })
      .order('language', { ascending: true });

    const result = await request;

    if (result.data !== null) {
      return result.data.map((r: any) => new TranslationMapper().toDomain(r));
    } else {
      throw new Error('SupabaseService.getTranslations returns no data');
    }
  }

  async getTranslations(
    textId: number | null,
    languageToFetch?: string
  ): Promise<ITranslationVM[]> {
    if (textId === null) {
      return [];
    }

    const cacheKey = `translations-${textId}-${languageToFetch}`;
    const hasCachedTranslation = this.localStorage2.translations.has(cacheKey);
    if (hasCachedTranslation) {
      const retval = this.localStorage2.translations.get(cacheKey);
      return retval ? retval : [];
    }

    const request = this.supabaseService
      .getSupabaseNativeClient()
      .from('translations')
      .select(
        `
      *,
      languages (
        value
      )
      `
      )
      .order('textId', { ascending: true })
      .order('language', { ascending: true });

    if (textId) {
      request.eq('textId', textId);
    }

    if (languageToFetch) {
      request.eq('language', languageToFetch);
    }

    const result = await request;

    if (result.data !== null) {
      const tmp: ITranslationVM[] = result.data.map((r: any) =>
        new TranslationMapper().toDomain(r)
      );

      this.localStorage2.translations.set(cacheKey, tmp);
      console.log('Cached translation ', cacheKey, tmp);
      return tmp;
    } else {
      throw new Error('SupabaseService.getTranslations returns no data');
    }
  }

  async getTextInDefaultLanguage(textId: number | undefined): Promise<string> {
    if (typeof textId === 'undefined') {
      return '';
    }

    return this.getDefaultLanguage().then((defaultLanguage) => {
      return this.getTranslations(textId, defaultLanguage).then(
        (translations) => {
          return (
            translations.find((f) => f.language === defaultLanguage)?.text ?? ''
          );
        }
      );
    });
  }

  async getMultipleTranslations(textIds: any[]): Promise<any> {
    console.debug('TranslationService.getMultipleTranslations', textIds);

    if (textIds === null || textIds.length === 0) {
      return [];
    }

    const textIdsToFetch = textIds
      .map((m) => m.textId)
      .filter((f) => f !== null);

    const request = this.supabaseService
      .getSupabaseNativeClient()
      .from('translations')
      .select(
        `
      *,
      languages (
        value
      )
      `
      )
      .order('textId', { ascending: true })
      .order('language', { ascending: true });

    request.in('textId', textIdsToFetch);

    const result = await request;

    if (result.data !== null) {
      const asDomain = result.data.map((r: any) =>
        new TranslationMapper().toDomain(r)
      );
      const groupedByTextId = _.groupBy(asDomain, (p) => p.textId);
      const retval = Object.values(groupedByTextId);

      textIds.forEach((textId, index) => {
        textId.translations = retval.find((r) => r[0].textId === textId.textId);
      });

      return textIds;
    } else {
      throw new Error(
        'TranslationService.getMultipleTranslations returns no data'
      );
    }
  }

  async machineTranslation(
    text: string,
    sourceLanguageCode: string,
    targetLanguageCode?: string,
    translationContext?: string
  ): Promise<any> {
    if (typeof targetLanguageCode === 'undefined') {
      throw new Error('You must provide a languageShortForm to translate to');
    }

    const { data, error } = await this.supabaseService
      .getSupabaseNativeClient()
      .functions.invoke('google-translate', {
        body: {
          text,
          sourceLanguageCode,
          targetLanguageCode,
          translationContext,
        },
      });

    if (error) {
      console.warn('Could not translate', error);
    }

    return data;
  }
}
