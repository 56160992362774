<label class="form-control w-full max-w-full">
  <div class="label">
    <span class="label-text">{{ title }}</span>
  </div>
  <label class="flex items-center gap-2">
    <input
      *ngIf="type === 'input'"
      type="text"
      class="grow input input-bordered"
      [(ngModel)]="defaultLanguageText"
      (change)="triggerOnChange()"
    />
    <textarea
      *ngIf="type === 'textarea'"
      cdkTextareaAutosize
      style="width: 100%; font-size: 1rem; min-height: 200px"
      class="textarea textarea-bordered"
      [(ngModel)]="defaultLanguageText"
      (change)="triggerOnChange()"
    ></textarea>
    <kbd
      class="kbd kbd-sm"
      *ngIf="defaultLanguageText"
      (click)="openTranslation(type)"
      >乐</kbd
    >
  </label>

  <div class="label" *ngIf="showDescription">
    <span class="label-text-alt">{{ defaultLanguageDescription }}</span>
  </div>
</label>
