import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { ITranslation } from '@app/shared/SupabaseTypes/ITranslation';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';

export class TranslationMapper implements Mapper<ITranslationVM> {
  toDomain(raw: any): ITranslationVM {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    tmp.languageLong = raw.languages.value;

    return tmp;
  }

  toPersistence(concrete: ITranslationVM): any {
    return {
      id: concrete.id,
      textId: concrete.textId,
      language: concrete.language,
      text: concrete.text,
      description: concrete.description,
      tenantId: concrete.tenantId,
    };
  }

  toPersistenceArray(concrete: ITranslationVM[]): ITranslationVM[] {
    return concrete.map((c) => {
      return {
        id: c.id,
        textId: c.textId,
        language: c.language,
        text: c.text,
        description: c.description,
        tenantId: c.tenantId,
      };
    });
  }
}
