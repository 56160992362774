<div class="login-container p-4">
  <app-stepper-page-template
    [title]="'magic.link.login.title'"
    [description]="'magic.link.login.description'"
    [showStep]="false"
    [progressTitle]="'Signup'"
    [image]="'assets/logo.svg'"
    [imageAlt]="'magic.link.login.imageAlt'"
  >
    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
      <div
        class="login-error text-red-500"
        [hidden]="!error || isLoading"
        translate
      >
        magic.link.login.incorrectUsernameOrPassword
      </div>
      <br />
      <div class="login-fields flex flex-col">
        <div class="inputWrapper mb-4">
          <label class="label">
            <span class="label-text" translate>general.email</span>
          </label>
          <input
            type="email"
            formControlName="email"
            autocomplete="email"
            placeholder="Email"
            class="input input-bordered w-full"
            required
          />
          <div
            *ngIf="
              loginForm.controls['email'].invalid &&
              loginForm.controls['email'].touched
            "
            class="text-red-500"
          >
            <span translate>login.emailRequired</span>
          </div>
        </div>

        <div
          class="form-actions flex items-center justify-between mb-4"
          *ngIf="!showRetryInformation"
        >
          <label class="cursor-pointer label">
            <input
              type="checkbox"
              formControlName="remember"
              class="checkbox checkbox-primary"
            />
            <span class="label-text ml-2" translate>login.rememberMe</span>
          </label>
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
          >
            <span *ngIf="!isLoading" translate>login.sendEmailLink</span>
            <app-loader
              [isLoading]="isLoading"
              class="inline-loader"
            ></app-loader>
          </button>
        </div>
        <a
          [href]="'/login-with-password'"
          id="loginWithPassword"
          class="text-blue-500"
          translate
        >
          login.loginWithPassword
        </a>
      </div>
    </form>
    <div *ngIf="showRetryInformation" class="email-info mt-4">
      <p translate>login.retryEmail</p>
    </div>
  </app-stepper-page-template>
</div>
<div class="gradientElement"></div>
