import { Injectable, OnInit } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { DateTime } from 'luxon';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';
import { OpeningHoursMapper } from '@app/shared/SupabaseTypes/opening-hours-mapper';
import { TranslationService } from '@app/shared/translation.service';
import { ISubscription } from '@app/shared/SupabaseTypes/ISubscription';
import { ISubscriptionUpdate } from '@app/shared/SupabaseTypes/ISubscriptionUpdate';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private supabaseService: SupabaseService) {}

  async getSubscription(): Promise<ISubscription> {
    const retval = await this.supabaseService
      .getSupabaseNativeClient()
      .from('subscription')
      .select()
      .single();

    if (retval.data) {
      return retval.data as ISubscription;
    } else {
      return {} as ISubscription;
    }
  }

  async updateSubscription(
    subscription: ISubscriptionUpdate
  ): Promise<ISubscription> {
    const { data, error } = await this.supabaseService
      .getSupabaseNativeClient()
      .functions.invoke('update-subscription', {
        body: subscription,
      });

    console.debug('updated subscription', data);

    if (data !== null) {
      return data as ISubscription;
    } else {
      throw new Error('Was not able to update subscription');
    }
  }

  // hvis brukeren har hatt en subscription tidligere, sagt opp avtalen og nå vil ha en ny avtale så må vi oppdatere den gamle raden i tabellen subscription
  // denne funksjonen henter ut en session for stripe checkout for en eksisterende bruker som jeg kan sette på payment table/pricing table
  async getCheckoutSessionForExisingCustomer(): Promise<string> {
    const { data, error } = await this.supabaseService
      .getSupabaseNativeClient()
      .functions.invoke('create-stripe-session-for-current-user');

    console.debug('getCheckoutSessionForExisingCustomer result', data);

    if (data !== null) {
      return data;
    } else {
      throw new Error(
        'Was not able to get stripe checkout session for previously created customer/subscription'
      );
    }
  }
}
