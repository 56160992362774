<div class="stepper-template">
  <div class="stepper-content">
    <div class="top-content">
      <div class="textLogo">Resmium</div>
      <app-language-selector></app-language-selector>
    </div>
    <div class="stepper" *ngIf="showStep">
      <span class="stepper-title"
        >{{ step }}/{{ numberOfSteps }}: {{ progressTitle | translate }}</span
      >
      <div
        class="w-full bg-gray-200 rounded-full h-2.5 backgroundColorSecondary"
      >
        <div
          class="h-2.5 rounded-full backgroundColorPrimary"
          [style.width.%]="(step / numberOfSteps) * 100"
        ></div>
      </div>
    </div>
    <div class="mt-8 flex flex-row">
      <div class="w-full" [ngClass]="{ 'md:w-1/2': image }">
        <h1 translate>{{ title }}</h1>
        <p *ngIf="description" translate>
          {{ description }}
        </p>
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="image"
        class="hidden md:block"
        [ngClass]="{ 'md:w-1/2': image }"
      >
        <img class="image" [src]="image" />
      </div>
    </div>
  </div>
</div>
