<form [formGroup]="restaurantForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-control">
    <label class="label">
      <span class="label-text" translate>restaurantDetails.restaurantName</span>
    </label>
    <input
      type="text"
      class="input input-bordered"
      formControlName="name"
      required
    />
    <div class="label">
      <span class="label-text-alt" translate
        >restaurantDetails.restaurantName.tooltip</span
      >
    </div>
    <div
      class="form-control-error"
      *ngIf="
        restaurantForm.controls['name'].invalid &&
        restaurantForm.controls['name'].touched
      "
    >
      <span translate>restaurantDetails.name.required</span>
    </div>
  </div>

  <div class="form-control mt-5">
    <label class="label">
      <span class="label-text" translate>restaurantDetails.maxGuests</span>
    </label>
    <input
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      min="1"
      max="9999999"
      class="input input-bordered"
      formControlName="regulatoryMaxGuestCapacity"
      required
    />
    <div class="label">
      <span class="label-text-alt" translate
        >restaurantDetails.maxGuests.tooltip</span
      >
    </div>
    <div
      class="form-control-error"
      *ngIf="
        restaurantForm.controls['regulatoryMaxGuestCapacity'].invalid &&
        restaurantForm.controls['regulatoryMaxGuestCapacity'].touched
      "
    >
      <span translate
        >restaurantDetails.regulatoryMaxGuestCapacity.required</span
      >
    </div>
  </div>

  <div class="mt-5 form-control">
    <label class="label">
      <span class="label-text" translate
        >restaurantDetails.defaultBookingLength</span
      >
    </label>
    <input
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      min="10"
      class="input input-bordered"
      formControlName="defaultBookingLength"
      required
    />
    <div class="label">
      <span class="label-text-alt" translate
        >restaurantDetails.defaultBookingLength.tooltip</span
      >
    </div>
    <div
      class="form-control-error"
      *ngIf="
        restaurantForm.controls['defaultBookingLength'].invalid &&
        restaurantForm.controls['defaultBookingLength'].touched
      "
    >
      <span translate>restaurantDetails.defaultBookingLength.required</span>
    </div>
  </div>

  <div class="mt-5 form-control">
    <label class="label">
      <span class="label-text" translate
        >restaurantDetails.reservationIntervals</span
      >
    </label>
    <input
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      min="10"
      max="600"
      class="input input-bordered"
      formControlName="reservationIntervals"
      required
    />
    <div class="label">
      <span class="label-text-alt" translate
        >restaurantDetails.reservationIntervals.tooltip</span
      >
    </div>
    <div
      class="form-control-error"
      *ngIf="
        restaurantForm.controls['reservationIntervals'].invalid &&
        restaurantForm.controls['reservationIntervals'].touched
      "
    >
      <span translate>restaurantDetails.reservationIntervals.required</span>
    </div>
  </div>

  <div class="form-actions mt-5">
    <button
      (click)="back()"
      class="btn btn-primary btn-outline"
      type="button"
      translate
    >
      general.back
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="restaurantForm.invalid || isLoading"
    >
      <span *ngIf="!isLoading" translate>general.next</span>
      <app-loader
        [isLoading]="isLoading"
        *ngIf="isLoading"
        class="inline-loader"
      ></app-loader>
    </button>
  </div>
</form>
