import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageSelectorComponent } from './language-selector.component';
import { CapitalizePipe } from '@app/pipes/CapitalizePipe';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LanguageSelectorComponent, CapitalizePipe],
  exports: [LanguageSelectorComponent, TranslateModule, CapitalizePipe],
})
export class I18nModule {}
