import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { Location } from '@angular/common';
import { CommunicationService } from '@app/communication-settings/communication.service';

@Component({
  selector: 'app-onboarding-number-of-areas',
  templateUrl: './onboarding-booking-confirmation.component.html',
  styleUrls: ['./onboarding-booking-confirmation.component.scss'],
})
export class OnboardingBookingConfirmationComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private emailservice: CommunicationService,
    private location: Location
  ) {}

  ngOnInit(): void {}

  no() {
    this.router.navigate([onboardingRouteSteps.step500]);
  }

  async yes() {
    this.buildEmailAndStoreInDb();
    this.router.navigate([onboardingRouteSteps.step500]);
  }

  private buildEmailAndStoreInDb() {
    // TODO denne må implementeres
  }
}
