import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { LoginWithPasswordComponent } from '@app/auth/login-with-password/login-with-password.component';
import { MagicLinkLoginComponent } from '@app/auth/magic-link-login/magic-link-login.component';
import { VerifyTokenComponent } from '@app/auth/verifyToken/verify-token.component';

const routes: Routes = [
  {
    path: 'login',
    component: MagicLinkLoginComponent,
    data: { title: marker('Login') },
  },
  {
    path: 'forgot-password',
    component: MagicLinkLoginComponent,
    data: { title: marker('forgot-password') },
  },
  {
    path: 'login-with-password',
    component: LoginWithPasswordComponent,
    data: { title: marker('Login with email and password') },
  },
  {
    path: 'verify-token',
    component: VerifyTokenComponent,
    data: { title: marker('Verfiy') },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
