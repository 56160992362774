import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';
import { TranslationService } from '@app/shared/translation.service';
import { TranslateService } from '@ngx-translate/core';

interface AreaCreatorDialogData {
  restaurantId: number;
  areaData: IArea | undefined;
}

@Component({
  selector: 'app-area-creator',
  templateUrl: './area-creator-dialog.component.html',
  styleUrls: ['./area-creator-dialog.component.scss'],
})
export class AreaCreatorComponentDialog {
  areaId: number | undefined;
  form!: FormGroup;

  constructor(
    private supabase: SupabaseService,
    public dialogRef: DialogRef<AreaCreatorComponentDialog>,
    private translationService: TranslationService,
    private translateService: TranslateService,
    @Inject(DIALOG_DATA) public data: AreaCreatorDialogData
  ) {
    const nameText = this.translateService.instant(
      'nameOfAreas.onboarding.name'
    );
    const descriptionText = this.translateService.instant(
      'nameOfAreas.onboarding.nameDesc'
    );
    this.form = new FormGroup({
      name: new FormControl<ITranslationVM[]>(
        // Dette er slik man lager et translatable input som ikke har data fra før
        [
          {
            title: nameText,
            description: descriptionText,
          },
        ]
      ),
      bookingPriority: new FormControl(100),
      availableOnline: new FormControl(true, { nonNullable: true }),
    });

    // has data, fill for editing
    if (data.areaData && data.areaData.id > 0) {
      this.areaId = data.areaData.id;
      this.form.get('bookingPriority')?.setValue(data.areaData.bookingPriority);
      this.form.get('availableOnline')?.setValue(data.areaData.availableOnline);

      if (data.areaData.nameTextId) {
        this.translationService
          .getTranslations(data.areaData.nameTextId)
          .then((translations) => {
            this.form.get('name')?.setValue(translations);
          });
      }
    } else {
      this.supabase.getTenant().then((tenant) => {
        // må sette defaultspråket på oversettelsen
        const formTranslationVal = this.form.get('name')?.value;
        if (formTranslationVal) {
          formTranslationVal[0].language = tenant.defaultLanguage;
          this.form.get('name')?.setValue(formTranslationVal);
        }
      });
    }
  }

  async onSubmit(e: any) {
    e.preventDefault();
    let values = this.form.getRawValue();
    if (this.form.valid && values.name && values.bookingPriority) {
      const textIdToStoreInDB: null | number =
        await this.translationService.storeTranslationsAndGetTextIdToStoreInDb(
          values.name as ITranslationVM[]
        );

      if (textIdToStoreInDB === null) {
        return;
      }

      this.supabase
        .createArea(
          this.data.restaurantId,
          this.areaId,
          textIdToStoreInDB,
          values.bookingPriority,
          values.availableOnline
        )
        .then((result) => {
          this.dialogRef.close(result);
        })
        .catch((err) => {
          console.log('error saving area', err);
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
