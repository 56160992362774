import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { IArea } from '@app/shared/SupabaseTypes/IArea';

export class AreaMapper implements Mapper<IArea> {
  toDomain(raw: any): IArea {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    return tmp;
  }

  toPersistence(concrete: IArea): any {}
}
