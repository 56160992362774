import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { SupabaseService } from '@app/shared/supabase.service';

@Component({
  selector: 'app-onboarding-multiple-languages',
  templateUrl: './onboarding-multiple-languages.component.html',
  styleUrls: ['./onboarding-multiple-languages.component.scss'],
})
export class OnboardingMultipleLanguagesComponent implements OnInit {
  constructor(
    private router: Router,
    public dialog: Dialog,
    public supabase: SupabaseService
  ) {}

  ngOnInit(): void {}

  no() {
    this.router.navigate([onboardingRouteSteps.step500]);
  }

  async yes() {
    await this.supabase.updateUsersProgressInOnboardingProcess(
      'wantsMultipleLanguages'
    );
    this.router.navigate([onboardingRouteSteps.step51]);
  }
}
