import { Component, Inject, Input, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

type Type = 'error' | 'warning' | 'info' | 'success';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss'],
})
export class InfoAlertComponent {
  title: string;
  type: Type = 'error';
  description: string;

  constructor(
    private dialogRef: DialogRef<InfoAlertComponent>,
    @Inject(DIALOG_DATA)
    public data: { title: string; description: string; type: Type }
  ) {
    this.title = data.title;
    this.description = data.description;
    this.type = data.type;
  }

  onOk() {
    // @ts-ignore
    this.dialogRef.close(false);
  }
}
