import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { ICommunicationMessageVM } from '@app/shared/SupabaseTypes/ICommunicationMessageVM';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';

export class CommunicationMessageMapper
  implements Mapper<ICommunicationMessageVM>
{
  toDomain(raw: any): ICommunicationMessageVM {
    throw new Error('Method not implemented.');
  }

  toPersistence(concrete: ICommunicationMessageVM): any {
    // Oversettelsene må lagres som textId'er i epost-tabellen. Her mappes disse dynamisk slik at man slipper å gjøre det manuelt.
    const translationTextIdsToAdd = Object.keys(concrete.translations);
    const translationsToUpsert = {};

    translationTextIdsToAdd.forEach((prop) => {
      translationsToUpsert[prop + 'TextId'] = this.getTextId(
        concrete.translations[prop]
      );
    });

    // @ts-ignore
    concrete.translations = undefined;
    concrete = { ...concrete, ...translationsToUpsert };

    return concrete;
  }

  private getTextId(translation: ITranslationVM[] | null): number | null {
    if (translation && translation.length > 0 && translation[0].textId) {
      return translation[0].textId;
    } else {
      return null;
    }
  }
}
