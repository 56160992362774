<div class="mb-6">
  <h2 translate>warnings.areYouSure</h2>
  <p>
    {{
      "warnings.areYouSureYouWantToDelete"
        | translate : { xSomeThangToDeleteX: name }
    }}
  </p>
  <p>{{ message }}</p>

  <div class="flex flex-row justify-center gap-4 mt-6">
    <button class="btn btn-secondary btn-outline" (click)="cancel()" translate>
      general.no
    </button>
    <button class="btn btn-primary" (click)="delete()" translate>
      general.yes
    </button>
  </div>
</div>
