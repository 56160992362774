import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { IUser } from '@app/shared/SupabaseTypes/IUser';

export class UserMapper implements Mapper<IUser> {
  toDomain(raw: any): IUser {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    if (raw.tenant_users) {
      // får ikke denne når jeg kjører update mot db
      tmp.role = raw.tenant_users[0].role;
    }

    return tmp;
  }

  toPersistence(concrete: IUser): any {}
}
