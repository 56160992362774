<div
  class="flex flex-col"
  *ngIf="languagesInUseByTenant && languagesInUseByTenant.length === 1"
>
  <h2 translate>translation.dialog.heading</h2>
  <p translate>translation.dialog.p1</p>
  <a
    [routerLink]="['my-account', 'add-remove-languages']"
    (click)="close()"
    *ngIf="onboardingIsCompleted"
    translate
    >translation.dialog.goHereToManageLanguages</a
  >

  <p *ngIf="!onboardingIsCompleted" translate>
    translation.dialog.onboardingMustBeCompleted
  </p>
</div>

<div
  class="flex flex-col"
  *ngIf="languagesInUseByTenant && languagesInUseByTenant.length > 1"
>
  <h2 translate>translation.dialog.translateFrom</h2>
  <h1
    *ngIf="getExistingTranslation(defaultLanguage)?.typeOfInput !== 'textarea'"
  >
    {{ getExistingTranslation(defaultLanguage)?.text }}
  </h1>
  <pre
    *ngIf="getExistingTranslation(defaultLanguage)?.typeOfInput === 'textarea'"
    class="card shadow-lg p-4 bordered"
    >{{ getExistingTranslation(defaultLanguage)?.text }}</pre
  >
  <p class="p-4">
    <span translate>translation.dialog.textDescription</span>: {{ description }}
  </p>

  <div class="mt-6">
    <button
      class="btn btn-active btn-sm"
      (click)="translateAllTexts()"
      translate
    >
      translation.dialog.aiTranslate
    </button>
  </div>

  <form [formGroup]="languagesFormArray" (ngSubmit)="onSubmit()">
    <div *ngFor="let l of languagesInUseByTenant; let i = index" class="mt-4">
      <h2 *ngIf="i === 1" translate>translation.dialog.translateTo</h2>
      <div class="form-control" *ngIf="i > 0">
        <div class="label">
          <span class="label-text">{{ l.value }}</span>
        </div>
        <label class="flex items-center gap-2">
          <input
            type="text"
            *ngIf="!isTextArea"
            class="grow input input-bordered"
            [value]="getExistingTranslation(l.id)?.text ?? ''"
            [formControlName]="l.id"
          />
          <textarea
            *ngIf="isTextArea"
            cdkTextareaAutosize
            style="width: 100%"
            class="textarea textarea-bordered"
            [value]="getExistingTranslation(l.id)?.text ?? ''"
            [formControlName]="l.id"
          ></textarea>
        </label>
      </div>
    </div>
  </form>

  <div class="mt-4">
    <button class="btn btn-secondary btn-outline" (click)="close()">
      {{ "general.cancel" | translate }}
    </button>

    <button class="btn btn-primary" (click)="onSubmit()">
      {{ "general.save" | translate }}
    </button>
  </div>
</div>
