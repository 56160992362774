import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
} from '@angular/core';

import { I18nService, IAppLanguage } from './i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  currentLang!: string;
  languagesUserCaresAbout: IAppLanguage[] = [];
  languages: IAppLanguage[] = [];
  currentLangText: string | undefined;

  constructor(
    private i18nService: I18nService,
    private translateService: TranslateService,
    private elementRef: ElementRef
  ) {
    const allSupportedLanguages = this.i18nService.supportedLanguagesObj();

    this.i18nService.currentLanguage$.subscribe((currentLang) => {
      if (currentLang) {
        // gjør dette siden noen koder kan inneholde nb-No feks, mens andre kan inneholde nb
        const found = allSupportedLanguages.find(
          (f) => f.code === currentLang.substring(0, 2)
        );
        this.currentLang = found?.code || currentLang;

        this.languagesUserCaresAbout = allSupportedLanguages.filter(
          (f) =>
            window.navigator.languages.some((lang) => lang.startsWith(f.code)) // bruker starts with her fordi noen nettlesere, feks på iphone returnerer nb-NO fremfor bare nb, mens chrome på desktop retujrnerer nb
        );

        // noen ganger returnerer nettleseren kun nb feks, og sannsynligheten for at de også kan engelsk er veldig høy så legger alltid til den
        if (!this.languagesUserCaresAbout.find((f) => f.code === 'en')) {
          this.languagesUserCaresAbout.push(
            allSupportedLanguages.find((f) => f.code === 'en')!
          );
        }
        this.languages = allSupportedLanguages.filter(
          (f) => !window.navigator.languages.includes(f.code)
        );
      }
    });
  }

  ngOnInit() {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    const detailsElement =
      this.elementRef.nativeElement.querySelector('details');
    if (detailsElement && !detailsElement.contains(target)) {
      detailsElement.removeAttribute('open');
    }
  }

  setLanguage(language: string) {
    this.currentLang = language;
    this.i18nService.initLanguage(language, false);
  }
}
