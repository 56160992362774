<app-stepper-page-template
  [title]="title"
  [description]="'onboarding.paymentSubtitle' | translate"
  [progressTitle]="'onboarding.paymentProgressTitle' | translate"
  [numberOfSteps]="7"
  [step]="6"
>
  <div class="flex w-full mt-12">
    <app-pricing-table class="w-full"></app-pricing-table>
  </div>

  <div class="mt-7">
    <i class="mt-1" translate>onboarding.notSure</i>
    <p translate>onboarding.notSureP1</p>
    <p class="mt-3" translate>onboarding.notSureP2</p>
  </div>
</app-stepper-page-template>
