import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { Location } from '@angular/common';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';

import { languages, TLanguages } from 'countries-list';

@Component({
  selector: 'app-onboarding-number-of-areas',
  templateUrl: './onboarding-default-language.component.html',
  styleUrls: ['./onboarding-default-language.component.scss'],
})
export class OnboardingDefaultLanguageComponent implements OnInit {
  form = new FormGroup({
    language: new FormControl(''),
  });
  public languageList: any[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: Dialog,
    private supabase: SupabaseService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.languageList = Object.keys(languages)
      .map((key) => {
        return {
          code: key,
          name: languages[key].name,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    const tenant = await this.supabase.getTenant();
    this.form.get('language')?.setValue(tenant.defaultLanguage);
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step5]);
  }

  async next() {
    if (this.form.valid) {
      const lang = this.form.get('language')?.value;
      if (lang) {
        await this.supabase.setTenantDefaultLanguage(lang);
      }

      await this.supabase.updateUsersProgressInOnboardingProcess(
        'defaultLanguageDone'
      );

      this.router.navigate([onboardingRouteSteps.step52]);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
