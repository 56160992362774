import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { ReactiveFormsModule } from '@angular/forms';

import { OnboardingOrganizationComponent } from './onboarding-organization/onboarding-organization.component';
import { OnboardingTablesAndAreasComponent } from './onboarding-tables-and-areas/onboarding-tables-and-areas.component';
import { OnboardingRestaurantComponent } from './onboarding-restaurant/onboarding-restaurant.component';
import { OnboardingRestaurantEditorComponent } from './onboarding-restaurant-editor/onboarding-restaurant-editor.component';
import { OnboardingSuccessComponent } from './onboarding-success/onboarding-success.component';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingOpeningHoursComponent } from './onboarding-opening-hours/onboarding-opening-hours.component';
import { OnboardingPaymentComponent } from '@app/onboarding/onboarding-payment/onboarding-payment.component';
import { OnboardingNumberOfAreasComponent } from '@app/onboarding/number-of-areas/onboarding-number-of-areas.component';
import { OnboardingNameOfAreasComponent } from '@app/onboarding/name-of-areas/onboarding-name-of-areas.component';
import { OnboardingRestaurantAddressCityCountryComponent } from '@app/onboarding/onboarding-restaurant-adress/onboarding-restaurant-address-city-country.component';
import { OnboardingMultipleLanguagesComponent } from '@app/onboarding/multiple-languages/onboarding-multiple-languages.component';
import { OnboardingDefaultLanguageComponent } from '@app/onboarding/multiple-languages-default-language/onboarding-default-language.component';
import { OnboardingAddLanguageComponent } from '@app/onboarding/multiple-languages-add-language/onboarding-add-language.component';
import { OnboardingBookingConfirmationComponent } from '@app/onboarding/booking-confirmation/onboarding-booking-confirmation.component';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';
import { PricingTableComponent } from '@app/onboarding/pricing-table/pricing-table.component';

@NgModule({
  declarations: [
    OnboardingComponent,
    OnboardingOrganizationComponent,
    OnboardingTablesAndAreasComponent,
    OnboardingRestaurantComponent,
    OnboardingRestaurantEditorComponent,
    OnboardingSuccessComponent,
    OnboardingPaymentComponent,
    OnboardingOpeningHoursComponent,
    OnboardingNumberOfAreasComponent,
    OnboardingNameOfAreasComponent,
    OnboardingRestaurantAddressCityCountryComponent,
    OnboardingMultipleLanguagesComponent,
    OnboardingDefaultLanguageComponent,
    OnboardingAddLanguageComponent,
    OnboardingBookingConfirmationComponent,
    PricingTableComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OnboardingRoutingModule,

    SharedModule,
    I18nModule,
    NgxMaterialIntlTelInputComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingModule {}
