import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { ITenant } from '@app/shared/SupabaseTypes/ITenant';
import { ISubscription } from '@app/shared/SupabaseTypes/ISubscription';
import { SubscriptionService } from '@app/shared/subscription.service';

@Component({
  selector: 'app-pricing-table',
  templateUrl: './pricing-table.component.html',
})
export class PricingTableComponent implements OnInit {
  isLoading = true;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private supabase: SupabaseService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.supabase.getTenant().then(async (tenant) => {
      this.loadStripeScript();

      return this.subscriptionService
        .getSubscription()
        .then(async (subscription: ISubscription) => {
          await this.createStripePricingTable(tenant, subscription);
          this.isLoading = false;
        });
    });
  }

  loadStripeScript(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }

  // jeg rendrer på denne måten fordi tenantId ikke var mulig å sette på webkomponenten til stripe direkte.
  async createStripePricingTable(
    tenant: ITenant,
    subscription: ISubscription
  ): Promise<void> {
    const stripePricingTable = this.renderer.createElement(
      'stripe-pricing-table'
    );

    const hasPreviouslyHadASubscription =
      subscription && subscription.stripeCustomerId;
    if (hasPreviouslyHadASubscription) {
      const checkoutsession =
        await this.subscriptionService.getCheckoutSessionForExisingCustomer();

      this.renderer.setAttribute(
        stripePricingTable,
        'customer-session-client-secret',
        checkoutsession['client_secret']
      );
    } else {
      const user = await this.supabase.getCurrentUser();
      this.renderer.setAttribute(
        stripePricingTable,
        'customer-email',
        user.email
      );
    }

    this.renderer.setAttribute(
      stripePricingTable,
      'client-reference-id',
      tenant.id
    );

    const localhost = {
      pricingTableId: 'prctbl_1QIUJZAceRqqjq99yLyNf0j4',
      publishableKey:
        'pk_test_51N8JoYAceRqqjq99lgHgdTfiiWnyxeKb3MYyE5EWdEeT25aoCU5uPBD5enbCuyqbVcjnOKPf28qhDJK5o5PT1SW000K6gMqSk7',
    };

    const staging = {
      pricingTableId: 'prctbl_1QISGgAceRqqjq99G4m8zC2R',
      publishableKey:
        'pk_test_51N8JoYAceRqqjq99lgHgdTfiiWnyxeKb3MYyE5EWdEeT25aoCU5uPBD5enbCuyqbVcjnOKPf28qhDJK5o5PT1SW000K6gMqSk7',
    };

    const production = {
      pricingTableId: 'prctbl_1QIUTBAceRqqjq99ACU3UfU9',
      publishableKey:
        'pk_live_51N8JoYAceRqqjq99geU8CA1XR93pvS6XD9jIvdITZz7G6Gw9v0Te4OkUCm2HQK2xU2pJmrBzSc9PWTHdK4cZwXQP000ayIJ27u',
    };

    let pricing = null;
    if (window.location.hostname.includes('localhost')) {
      pricing = localhost;
    } else if (window.location.hostname.includes('staging')) {
      pricing = staging;
    } else {
      pricing = production;
    }

    this.renderer.setAttribute(
      stripePricingTable,
      'pricing-table-id',
      pricing.pricingTableId
    );
    this.renderer.setAttribute(
      stripePricingTable,
      'publishable-key',
      pricing.publishableKey
    );

    this.renderer.appendChild(this.el.nativeElement, stripePricingTable);
  }
}
