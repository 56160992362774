<app-stepper-page-template
  [title]="'onboarding.defaultLanguages.title'"
  [description]="'onboarding.defaultLanguages.stepDescription'"
  [progressTitle]="'onboarding.defaultLanguages.progressTitle'"
  [numberOfSteps]="7"
  [step]="5"
>
  <form [formGroup]="form" class="mt-10 mb-10">
    <label class="form-control max-w-72">
      <div class="label">
        <span class="label-text" translate
          >onboarding.defaultLanguages.label</span
        >
      </div>
      <select formControlName="language" class="select select-bordered">
        <option *ngFor="let l of languageList" [ngValue]="l.code">
          {{ l.name }}
        </option>
      </select>
    </label>
  </form>

  <div class="mt-6 flex flex-row flex-wrap w-full justify-between">
    <button
      class="btn btn-primary btn-outline min-w-36"
      (click)="back()"
      translate
    >
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
