/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { supabaseIntegration } from '@supabase/sentry-js-integration';
import { SupabaseClient } from '@supabase/supabase-js';

if (environment.production || environment.staging) {
  Sentry.init({
    dsn: 'https://2e05c3a0711e770545af7770e1130c8a@o4507826732466176.ingest.de.sentry.io/4507826735218768',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      supabaseIntegration(SupabaseClient, Sentry, {
        tracing: true,
        breadcrumbs: true,
        errors: true,
      }),
      Sentry.feedbackIntegration({
        isNameRequired: true,
        isEmailRequired: true,
        showBranding: false,
        colorScheme: 'light',
      }),
    ],
    debug: false,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'app.resmium.com',
      'staging-app.resmium.com',
    ],
    release: environment.version,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment:
      environment.production && !environment.staging
        ? 'production'
        : environment.staging
        ? 'staging'
        : 'development',
  });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
