import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared';

import { I18nModule } from '@app/i18n';
import { LoginWithPasswordComponent } from '@app/auth/login-with-password/login-with-password.component';
import { MagicLinkLoginComponent } from '@app/auth/magic-link-login/magic-link-login.component';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { VerifyTokenComponent } from '@app/auth/verifyToken/verify-token.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,

    I18nModule,
    AuthRoutingModule,
  ],
  declarations: [
    MagicLinkLoginComponent,
    LoginWithPasswordComponent,
    VerifyTokenComponent,
  ],
})
export class AuthModule {}
