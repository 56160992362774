import { Component, Inject, OnInit } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { ITable } from '@app/shared/SupabaseTypes/ITable';
import { TranslateService } from '@ngx-translate/core';

interface TableEditorDialogData {
  restaurantId: number;
  areaData: IArea;
  tableData?: ITable;
  tableNumberSuggestion: number;
}

@Component({
  selector: 'app-table-editor-dialog',
  templateUrl: './table-editor-dialog.component.html',
  styleUrls: ['./table-editor-dialog.component.scss'],
})
export class TableEditorDialogComponent implements OnInit {
  tableForm = new FormGroup({
    name: new FormControl('', Validators.required),
    capacityTo: new FormControl(4),
    capacityFrom: new FormControl(1),
    bookingPriority: new FormControl(100),
    shape: new FormControl('square'),
  });
  restaurantId: number = -1;
  table: ITable | undefined;
  area: IArea;
  tableShape: string[] = ['square', 'round', 'rectangle', 'oval'];

  constructor(
    private supabase: SupabaseService,
    public dialogRef: DialogRef<TableEditorDialogData>,
    @Inject(DIALOG_DATA) public data: TableEditorDialogData
  ) {
    this.table = data.tableData;
    this.restaurantId = data.restaurantId;
    this.area = data.areaData;

    if (!this.table) {
      // user creating a new table
      this.tableForm.get('name')?.setValue(data.tableNumberSuggestion + '');
    }
  }

  ngOnInit(): void {
    if (this.table && this.area) {
      this.tableForm.get('name')?.setValue(this.table.name);
      this.tableForm.get('capacityTo')?.setValue(this.table.capacityTo);
      this.tableForm.get('capacityFrom')?.setValue(this.table.capacityFrom);
      this.tableForm
        .get('bookingPriority')
        ?.setValue(this.table.bookingPriority);
      this.tableForm.get('shape')?.setValue(this.table.shape);
    }
  }

  onSubmit() {
    let values = this.tableForm.getRawValue();
    console.log('values', values);
    if (
      this.tableForm.valid &&
      values.name &&
      values.capacityTo &&
      values.capacityFrom &&
      values.shape &&
      values.bookingPriority
    ) {
      this.supabase
        .upsertTable(
          this.area.id,
          this.table?.id ? this.table?.id : undefined,
          values.name,
          values.capacityFrom,
          values.capacityTo,
          values.shape,
          undefined,
          undefined,
          undefined,
          values.bookingPriority
        )
        .then((result) => {
          this.dialogRef.close(result);
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
