import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { ISmsHistory } from '@app/shared/SupabaseTypes/ISmsHistory';
import { CustomerMapper } from '@app/shared/SupabaseTypes/customer-mapper';

export class SmsHistoryMapper implements Mapper<ISmsHistory> {
  toDomain(raw: any): ISmsHistory {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    tmp.customer = new CustomerMapper().toDomain(raw.customer);

    return tmp;
  }

  toPersistence(concrete: ISmsHistory): any {}
}
