import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper-page-template',
  templateUrl: 'stepper-page-template.component.html',
  styleUrls: ['stepper-page-template.component.scss'],
})
export class StepperPageTemplateComponent implements OnInit {
  @Input() title: string = 'Page title';
  @Input() description: string | undefined;
  @Input() progressTitle: string = 'Step title';
  @Input() step: number = 0;
  @Input() numberOfSteps: number = 4;
  @Input() showStep: boolean = true;
  @Input() image: string | undefined;
  @Input() imageAlt: string | undefined;

  constructor() {}

  ngOnInit() {}
}
