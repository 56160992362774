<app-stepper-page-template
  [title]="'nameOfAreas.onboarding.title'"
  [description]="'nameOfAreas.onboarding.stepDescription'"
  [progressTitle]="'nameOfAreas.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="4"
>
  <p class="mt-5" translate>nameOfAreas.onboarding.priorityHelperText</p>
  <p class="mt-5" translate>nameOfAreas.onboarding.availableOnlineHelperText</p>
  <form [formGroup]="form" class="mt-10 mb-10">
    <ng-container formArrayName="areas">
      <ng-container *ngFor="let areaForm of areas.controls; let i = index">
        <h3>{{ "nameOfAreas.onboarding.name" | translate }} {{ i + 1 }}</h3>
        <div
          [formGroup]="getFormGroup(areaForm)"
          class="flex flex-row- flex-wrap gap-2 mb-6"
        >
          <app-translateable-input
            formControlName="name"
            [title]="'Name'"
          ></app-translateable-input>

          <div class="form-control">
            <label class="label">
              <span class="label-text" translate
                >nameOfAreas.onboarding.priority</span
              >
            </label>
            <input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              class="input input-bordered"
              formControlName="bookingPriority"
              required
            />
            <div
              class="form-control-error"
              *ngIf="
                shouldShowRequiredMessage(
                  getFormGroup(areaForm),
                  'bookingPriority'
                )
              "
            >
              <span translate>required</span>
            </div>
          </div>

          <div class="form-control">
            <label
              class="label cursor-pointer flex flex-row sm:flex-col gap-4 leading-8"
            >
              <div class="label-text" translate>
                nameOfAreas.onboarding.availableOnline
              </div>
              <div>
                <input
                  formControlName="availableOnline"
                  type="checkbox"
                  class="checkbox checkbox-primary"
                />
              </div>
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>

  <div class="mt-6 flex flex-row flex-wrap w-full justify-between">
    <button
      class="btn btn-primary btn-outline min-w-36"
      (click)="back()"
      translate
    >
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
