import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { ITenant } from '@app/shared/SupabaseTypes/ITenant';

export class TenantMapper implements Mapper<ITenant> {
  toDomain(raw: any): ITenant {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    if (tmp.hourFormat === 'HH24:MI') {
      tmp.hourFormatForDateTime = 'HH:mm';
    } else {
      tmp.hourFormatForDateTime = 'hh:mm a';
    }

    if (tmp.dateFormat === 'DD.MM.YYYY') {
      tmp.dateFormat = 'dd.MM.yyyy';
    } else {
      tmp.dateFormat = 'MM/dd/yyyy';
    }

    return tmp;
  }

  toPersistence(concrete: ITenant): any {}
}
