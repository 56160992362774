import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { I18nService } from '@app/i18n';
import { StripeService } from '@app/shared/stripe.service';
import { AuthenticationService } from '@app/auth';
import { TranslationService } from '@app/shared/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-onboarding-organization',
  templateUrl: './onboarding-payment.component.html',
  styleUrls: ['./onboarding-payment.component.scss'],
})
export class OnboardingPaymentComponent implements OnInit {
  isLoading: boolean = false;
  private username!: string;
  title: string = '';
  isProduction: boolean = environment.production;

  private clickCount = 0;
  private firstClickTime: number | null = null;

  constructor(
    private router: Router,
    private supabase: SupabaseService,
    private i18nService: I18nService,
    private stripeService: StripeService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.supabase.getTenant().then(() => true);
    this.username = await this.authenticationService.username();
    let currentUser = await this.supabase.getCurrentUser();
    if (currentUser.name) {
      const firstname = currentUser.name.split(' ')[0];
      this.translateService
        .stream('onboarding.payment.title', {
          name: firstname,
        })
        .subscribe((res: string) => {
          this.title = res;
        });
      console.log('done');
    }
  }

  submit() {
    // this.isLoading = true; Start loading her som sjekker at subscription er aktivert

    this.supabase
      .verifySubscription()
      .then(() => {
        console.log(
          'Subscription is verified through backend webhook. Go on with the process'
        );
      })
      .catch((error: any) => {
        console.error('Error when trying to verify subscription', error);
      })
      .then(() => {
        this.isLoading = false;
        this.router.navigate([onboardingRouteSteps.step500]);
      });
  }
  // this.isLoading = false;
  // this.router.navigate([onboardingRouteSteps.step4, this.restaurantId]);
}
