import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { environment } from '@env/environment';
import { SupabaseService } from '@app/shared/supabase.service';

export const oboardingGuardFn: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const supabase: SupabaseService = inject(SupabaseService);
  const auth: AuthenticationService = inject(AuthenticationService);

  if (!(await auth.isLoggedIn())) {
    // only run this guard if the user is logged in
    return true;
  }

  const username = await auth.username();
  const isOnboardingCompleted =
    localStorage.getItem(`${username}_onboarding_status`) === 'completed';
  if (isOnboardingCompleted) {
    return true;
  }
  return await supabase.getOnboardingProcess().then((process) => {
    if (process !== null && process[environment.finalOnboardingStep] !== null) {
      localStorage.setItem(`${username}_onboarding_status`, 'completed');
      return true;
    }
    router.navigate(['/onboarding/organization'], { replaceUrl: true });
    return false;
  });
};
