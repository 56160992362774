import { Injectable } from '@angular/core';
import { SupabaseService } from '@app/shared/supabase.service';
import { TranslationService } from '@app/shared/translation.service';
import { IBookingConfirmationEmailVM } from '@app/shared/SupabaseTypes/IBookingConfirmationEmailVM';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { TranslationMapper } from '@app/shared/SupabaseTypes/translation-mapper';
import * as _ from 'lodash';
import { ICommunicationMessageVM } from '@app/shared/SupabaseTypes/ICommunicationMessageVM';
import { CommunicationMessageMapper } from '@app/shared/SupabaseTypes/communication-message-mapper';
import { IBookingConfirmationSmsVM } from '@app/shared/SupabaseTypes/IBookingConfirmationSmsVM';
import { IBookingCancellationSmsVM } from '@app/shared/SupabaseTypes/IBookingCancellationSmsVM';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(
    private translationService: TranslationService,
    private supabaseService: SupabaseService
  ) {}

  async getBookingConfirmationEmail(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'email_booking_confirmation_2'
    );
  }

  async getBookingConfirmationSms(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'sms_booking_confirmation'
    );
  }

  async getBookingCancellationSms(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'sms_booking_cancellation'
    );
  }

  async getBookingCancellationConfirmationEmail(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'email_booking_cancellation_confirmation'
    );
  }
  async getCancellationByRestaurantEmail(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'email_cancellation_by_restaurant'
    );
  }

  async getBookingModifiedByRestaurantEmail(
    restaurantId: number | null
  ): Promise<ICommunicationMessageVM> {
    return this.buildCommunicationMessageWithTranslations(
      restaurantId,
      'email_booking_modified_by_restaurant'
    );
  }

  async buildCommunicationMessageWithTranslations(
    restaurantId: number | null,
    table: string
  ): Promise<ICommunicationMessageVM> {
    let queryResult: PostgrestSingleResponse<any>;

    if (restaurantId === null) {
      queryResult = await this.supabaseService
        .getSupabaseNativeClient()
        .from(table)
        .select()
        .is('restaurantId', null)
        .maybeSingle();
    } else {
      queryResult = await this.supabaseService
        .getSupabaseNativeClient()
        .from(table)
        .select()
        .eq('restaurantId', restaurantId)
        .maybeSingle();
    }

    const queryData = queryResult.data;

    if (queryData === null) {
      return {} as ICommunicationMessageVM;
    }

    console.debug('getEmailWithTranslations() queryData', queryData);

    const textIdsForTranslationsToBeFetched = this.getKeysWithTextId(queryData);

    const translations = await this.translationService.getMultipleTranslations(
      textIdsForTranslationsToBeFetched
    );

    // only interested in the translations
    const tmpTranslations = translations.reduce((acc: any, item: any) => {
      acc[item.name] = item.translations;
      return acc;
    }, {});

    const emailWithTranslations: ICommunicationMessageVM = {
      translations: tmpTranslations,
    };

    // hent alle feltene som ikke er oversettelser og sett de på retur-objektet
    Object.keys(queryData)
      .filter((key) => !key.endsWith('TextId'))
      .forEach((key) => {
        emailWithTranslations[key] = queryData[key];
      });

    return emailWithTranslations;
  }

  sendPreviewBookingConfirmationEmail() {
    throw new Error('Not implemented yet');
  }

  private getKeysWithTextId(queryData: any): any[] {
    const retVal: any[] = [];
    Object.keys(queryData)
      .filter((key) => key.endsWith('TextId'))
      .forEach((key) =>
        retVal.push({
          name: key.split('TextId')[0],
          textId: queryData[key],
        })
      );

    return retVal;
  }

  async upsertBookingConfirmationSms(email: IBookingConfirmationSmsVM) {
    await this.upsertEmail(email, 'sms_booking_confirmation');
  }
  async upsertBookingCancellationSms(email: IBookingCancellationSmsVM) {
    await this.upsertEmail(email, 'sms_booking_cancellation');
  }

  async upsertBookingConfirmationEmail(email: IBookingConfirmationEmailVM) {
    await this.upsertEmail(email, 'email_booking_confirmation_2');
  }

  async upsertBookingCancellationEmail(email: IBookingConfirmationEmailVM) {
    await this.upsertEmail(email, 'email_booking_cancellation_confirmation');
  }

  async upsertEmail(email: ICommunicationMessageVM, tableName: string) {
    email.tenantId = (await this.supabaseService.getTenant()).id;

    email.translations = await this.translationService.upsertTranslationsObject(
      email.translations
    );

    email = new CommunicationMessageMapper().toPersistence(email);

    await this.supabaseService
      .getSupabaseNativeClient()
      .from(tableName)
      .upsert(email)
      .select();
  }
}
