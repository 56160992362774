<div class="opening-hours-creator mb-6">
  <h1>
    <span translate>general.edit</span>
    <span translate>{{ weekday }}</span>
  </h1>

  <form [formGroup]="openingHourForm" (ngSubmit)="onSubmit()">
    <div class="form-wrapper">
      <app-translateable-input
        formControlName="name"
        [title]="'general.name' | translate"
      ></app-translateable-input>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text" translate>openingHours.opens</span>
        </label>
        <input
          type="time"
          class="input input-bordered"
          formControlName="opens"
        />
      </div>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text" translate>openingHours.closes</span>
        </label>
        <input
          type="time"
          class="input input-bordered"
          formControlName="closes"
        />
      </div>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text" translate
            >openingHours.cutoffInMinutesLabel</span
          >
        </label>
        <input
          type="text"
          class="input input-bordered"
          formControlName="bookingClosesMinutesBefore"
          required
        />
        <label class="label">
          <span class="label-text" translate
            >openingHours.cuttoffInMinutesTooltip</span
          >
        </label>
        <div
          class="form-control-error"
          *ngIf="
            openingHourForm.controls['bookingClosesMinutesBefore'].invalid &&
            openingHourForm.controls['bookingClosesMinutesBefore'].touched
          "
        >
          <span translate>openingHours.cutoffInMinutesRequired</span>
        </div>

        <div class="form-control mt-5">
          <label class="label">
            <span class="label-text" translate
              >openingHours.activeFromDate</span
            >
          </label>
          <input
            type="date"
            class="input input-bordered"
            formControlName="activeFromDate"
          />
        </div>

        <div class="form-control mt-5">
          <label class="label">
            <span class="label-text" translate>openingHours.activeToDate</span>
          </label>
          <input
            type="date"
            class="input input-bordered"
            formControlName="activeToDate"
          />
        </div>

        <div class="form-control mt-5">
          <label class="label justify-start gap-3">
            <span class="label-text" translate>openingHours.isOpen</span>
            <input
              type="checkbox"
              class="checkbox checkbox-primary"
              formControlName="isOpen"
            />
          </label>
          <label translate>openingHours.isOpenDesc</label>
        </div>

        <div class="form-control mt-5">
          <label class="label justify-start gap-3">
            <span class="label-text" translate
              >openingHours.specialOpeningHoursThatShouldOverrideOthers</span
            >
            <input
              type="checkbox"
              class="checkbox checkbox-primary"
              formControlName="specialOpeningHoursThatShouldOverrideOthers"
            />
          </label>
          <label translate
            >openingHours.specialOpeningHoursThatShouldOverrideOthersDesc</label
          >
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-4 mt-6">
      <button class="btn btn-secondary btn-outline" (click)="close()" translate>
        general.close
      </button>
      <button class="btn btn-primary" type="submit" translate>
        general.save
      </button>
    </div>
  </form>
</div>
