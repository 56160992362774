import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { RestaurantEditorComponent } from './components/restaurant-editor/restaurant-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepperPageTemplateComponent } from './templates/stepper-page-template/stepper-page-template.component';
import { I18nModule } from '@app/i18n';
import { DeleteAlertComponent } from './components/delete-alert/delete-alert.component';
import { InfoAlertComponent } from './components/info-alert/info-alert.component';
import { TableEditorDialogComponent } from './components/table-editor-dialog/table-editor-dialog.component';
import { AreaCreatorComponentDialog } from './components/area-creator-dialog/area-creator-dialog.component';
import { AreaTablesViewComponent } from './components/area-tables-view/area-tables-view.component';
import { OpeningHoursCreatorDialogComponent } from './components/opening-hours-creator-dialog/opening-hours-creator-dialog.component';
import { OpeningHoursEditorDialogComponent } from './components/opening-hours-editor-dialog/opening-hours-editor-dialog.component';
import { TipComponent } from './components/tip/tip.component';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { ConfigLayoutComponent } from './layout/config-layout/config-layout.component';
import { RouterModule } from '@angular/router';
import { TranslatableInputComponent } from '@app/shared/components/translateable-input/translatable-input.component';
import { TranslationDialogComponent } from '@app/shared/components/translation-dialog/translation-dialog.component';
import { Datei18nPipe } from '@app/pipes/date-i18n.pipe';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NotImplementedYetComponent } from '@app/shared/components/booking-reminder-sms/not-implemented-yet.component';
import { DoYouReallyWantToDialogComponent } from '@app/shared/components/do-you-really-want-to-dialog/do-you-really-want-to-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormsModule,
    TextFieldModule,
  ],
  declarations: [
    LoaderComponent,
    RestaurantEditorComponent,
    StepperPageTemplateComponent,
    DeleteAlertComponent,
    InfoAlertComponent,
    ShimmerComponent,
    TableEditorDialogComponent,
    AreaCreatorComponentDialog,
    AreaTablesViewComponent,
    OpeningHoursCreatorDialogComponent,
    OpeningHoursEditorDialogComponent,
    TipComponent,
    ConfigLayoutComponent,
    TranslatableInputComponent,
    TranslationDialogComponent,
    Datei18nPipe,
    NotImplementedYetComponent,
    DoYouReallyWantToDialogComponent,
  ],
  exports: [
    LoaderComponent,
    RestaurantEditorComponent,
    StepperPageTemplateComponent,
    DeleteAlertComponent,
    InfoAlertComponent,
    ShimmerComponent,
    AreaTablesViewComponent,
    ConfigLayoutComponent,
    TipComponent,
    TranslatableInputComponent,
    I18nModule,
    Datei18nPipe,
    TextFieldModule,
    NotImplementedYetComponent,
    DoYouReallyWantToDialogComponent,
  ],
})
export class SharedModule {}
