import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { TranslationDialogComponent } from '@app/shared/components/translation-dialog/translation-dialog.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationService } from '@app/shared/translation.service';
import { ITranslationVM } from '@app/shared/SupabaseTypes/ITranslationVM';

@Component({
  selector: 'app-translateable-input',
  templateUrl: './translatable-input.component.html',
  styleUrls: ['./translatable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TranslatableInputComponent,
    },
  ],
})
export class TranslatableInputComponent
  implements OnInit, ControlValueAccessor
{
  tenantDefaultLanguage: string = '';

  @Input() title!: string; // used above the input
  @Input() showDescription = true; // used above the input
  @Input() type: string = 'input';

  private translations: ITranslationVM[] = [];

  constructor(
    public dialog: Dialog,
    private translationService: TranslationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.tenantDefaultLanguage =
      await this.translationService.getDefaultLanguage();

    if (
      typeof this.translations === 'undefined' ||
      this.translations.length === 0
    ) {
      throw new Error(
        'ITranslationsVM[] must be passed to this component before component is initialized. This should not happen. Could it be that you had a translation in the database that was deleted? Or, maybe you forgot to set the default language?'
      );
    }
  }

  openTranslation(typeOfInput: string) {
    let dialogRef = this.dialog.open(TranslationDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: this.translations,
    });

    // @ts-ignore dialogRef skulle muligens her vært
    dialogRef.closed.subscribe((result: ITranslationVM[]) => {
      if (result && result.length > 0) {
        this.translations = result;
        this.onChange(result);
      }
    });
  }

  get defaultLanguageText(): string {
    return (
      (this.translations &&
        Array.isArray(this.translations) &&
        this.translations.find((t) => t.language === this.tenantDefaultLanguage)
          ?.text) ||
      ''
    );
  }
  set defaultLanguageText(value: string) {
    let find = this.translations.find(
      (t) => t.language === this.tenantDefaultLanguage
    );

    const inferDefaultLanguage =
      typeof find === 'undefined' &&
      this.translations.length === 1 &&
      typeof this.translations[0].language === 'undefined';
    if (inferDefaultLanguage) {
      this.translations[0].language = this.tenantDefaultLanguage;
      find = this.translations[0];
      // throw new Error('Missing translation entry for default language. This should not happen. Have you sent in a TranslationVM[] without the default language?');
    }

    if (find) {
      find.text = value;
      this.triggerOnChange();
    }
  }

  get defaultLanguageDescription(): string {
    return (
      (this.translations &&
        Array.isArray(this.translations) &&
        this.translations.find((t) => t.language === this.tenantDefaultLanguage)
          ?.description) ||
      ''
    );
  }

  triggerOnChange() {
    this.onChange(this.translations);
  }

  onChange = (obj: any) => {}; // bør få inn noe annet enn any her

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: ITranslationVM[]): void {
    this.translations = obj;
  }
}
