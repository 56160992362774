<div *ngIf="this.tables && this.tables.length > 0">
  <h3 class="header" translate>tablesAndAreas.view.title</h3>
  <div class="overflow-x-auto">
    <table class="table table-auto w-full">
      <thead>
        <tr>
          <th translate>general.name</th>
          <th translate>tablesAndAreas.maxCapacity</th>
          <th class="hidden sm:table-cell" translate>tablesAndAreas.shape</th>
          <th class="hidden sm:table-cell" translate>
            tablesAndAreas.shape.blockedUntil
          </th>
          <th class="hidden sm:table-cell" translate>
            tableAndAreas.bookingPriority
          </th>
          <th class="edit-section"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of tables">
          <td>{{ element.name }}</td>
          <td>{{ element.capacityFrom }} - {{ element.capacityTo }}</td>
          <td class="hidden sm:table-cell" translate>
            {{ "tableShapes." + element.shape }}
          </td>
          <td class="hidden sm:table-cell">{{ element.blockedUntil }}</td>
          <td class="hidden sm:table-cell">{{ element.bookingPriority }}</td>
          <td class="table-button">
            <div class="flex flex-row gap-3">
              <button
                class="btn btn-outline"
                (click)="onEdit(element)"
                translate
              >
                general.edit
              </button>
              <button
                class="btn btn-outline btn-danger"
                (click)="onDelete(element)"
                translate
              >
                general.delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
