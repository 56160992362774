<h1 class="text-2xl font-bold mb-4" translate>areaCreator.newArea</h1>
<div class="mb-4">
  <p translate>areaCreator.description</p>
</div>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)">
  <div class="inputs mb-4">
    <div class="form-control mb-4">
      <app-translateable-input
        formControlName="name"
        [title]="'nameOfAreas.onboarding.name' | translate"
      ></app-translateable-input>
    </div>

    <div class="form-control mb-4">
      <label class="label">
        <span class="label-text" translate>areaCreator.bookingPriority</span>
      </label>
      <input
        type="text"
        formControlName="bookingPriority"
        [placeholder]="'areaCreator.bookingPriority' | translate"
        class="input input-bordered w-full"
        required
      />
    </div>

    <div class="form-control mb-4">
      <label class="label cursor-pointer">
        <span class="label-text" translate>areaCreator.onlineBooking</span>
        <input
          type="checkbox"
          formControlName="availableOnline"
          class="checkbox checkbox-primary"
        />
      </label>
    </div>
  </div>
  <div class="actions flex justify-between">
    <button type="button" class="btn btn-outline" (click)="close()" translate>
      general.cancel
    </button>
    <button class="btn btn-primary" type="submit" translate>
      general.save
    </button>
  </div>
</form>
