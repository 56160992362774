import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { SupabaseService } from '@app/shared/supabase.service';
import { AuthenticationService } from '@app/auth';

@Component({
  selector: 'app-login',
  templateUrl: './magic-link-login.component.html',
  styleUrls: ['./magic-link-login.component.scss'],
})
export class MagicLinkLoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  session: any;
  showRetryInformation = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private readonly supabase: SupabaseService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.supabase.authChanges((_, session) => {
      this.session = session;
      return this.session;
    });

    if (await this.authenticationService.isLoggedIn()) {
      this.router.navigate(
        [this.route.snapshot.queryParams['redirect'] || '/overview'],
        { replaceUrl: true }
      );
    }
  }

  login() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(this.loginForm.value.email)) {
      return;
    }

    this.isLoading = true;
    try {
      this.supabase.signIn(this.loginForm.value.email).then((res) => {
        this.router.navigate(['/verify-token'], {
          replaceUrl: true,
          queryParams: { email: this.loginForm.value.email },
        });
      });
    } catch (error) {
      // Dette skal vel aldri skje?
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      remember: true,
    });
  }
}
