<app-stepper-page-template
  [title]="'onboarding.addLanguages.title'"
  [description]="'onboarding.addLanguages.stepDescription'"
  [progressTitle]="'onboarding.addLanguages.progressTitle'"
  [numberOfSteps]="7"
  [step]="5"
>
  <p class="mt-3" translate>onboarding.addLanguages.AI</p>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-6">
    <div class="flex flex-col sm:flex-row gap-5 sm:items-end">
      <label class="form-control">
        <div class="label">
          <span class="label-text" translate
            >account.addRemoveLanguages.selectLanguageToAdd</span
          >
        </div>
        <select formControlName="language" class="select select-bordered">
          <option *ngFor="let l of languages" [ngValue]="l.id">
            {{ l.value }}
          </option>
        </select>
      </label>

      <button class="btn btn-primary" type="submit" translate>
        account.addRemoveLanguages.addLanguage
      </button>
    </div>
  </form>

  <div *ngIf="languagesInUseByTenant.length === 1" class="mt-7">
    <p>{{ "account.addRemoveLanguages.noLanguagesAddedYet" | translate }}</p>
  </div>

  <div *ngIf="languagesInUseByTenant.length > 1" class="mt-7">
    <h2>{{ "account.addRemoveLanguages.yourLanguages" | translate }}</h2>
  </div>

  <div *ngFor="let language of languagesInUseByTenant" class="mt-4">
    <div *ngIf="language.id !== tenantData.defaultLanguage" class="">
      <div class="flex flex-row">
        <h3>{{ language.value }}</h3>
        <button
          class="btn btn-xs ml-4"
          (click)="delete(language.id)"
          *ngIf="language.id !== tenantData.defaultLanguage"
          translate
        >
          account.addRemoveLanguages.remove
        </button>
      </div>
    </div>
  </div>

  <div class="mt-8 flex flex-row flex-wrap w-full justify-between">
    <button
      class="btn btn-primary btn-outline min-w-36"
      (click)="back()"
      translate
    >
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
