import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { IOnboardingProcess } from './IOnboardingProcess';

export class OnboardingProcessMapper implements Mapper<IOnboardingProcess> {
  toDomain(raw: any): IOnboardingProcess {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = tmp.created_at
      ? DateTime.fromISO(tmp.created_at).toUTC(0, { keepLocalTime: true })
      : null; // litt hacky her, men jeg ignorerer foreløpig bare tidssoner
    tmp.restaurantDetailsCompleted = tmp.restaurantDetailsCompleted
      ? DateTime.fromISO(tmp.restaurantDetailsCompleted).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    tmp.areaAndTablesSetupCompleted = tmp.areaAndTablesSetupCompleted
      ? DateTime.fromISO(tmp.areaAndTablesSetupCompleted).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    tmp.organizationInfoCompleted = tmp.organizationInfoCompleted
      ? DateTime.fromISO(tmp.organizationInfoCompleted).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    tmp.onboardingCompleted = tmp.onboardingCompleted
      ? DateTime.fromISO(tmp.onboardingCompleted).toUTC(0, {
          keepLocalTime: true,
        })
      : null;

    tmp.multipleAreasDone = tmp.multipleAreasDone
      ? DateTime.fromISO(tmp.multipleAreasDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.openingHoursDone = tmp.openingHoursDone
      ? DateTime.fromISO(tmp.openingHoursDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.addMultipleLanguagesDone = tmp.addMultipleLanguagesDone
      ? DateTime.fromISO(tmp.addMultipleLanguagesDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.defaultLanguageDone = tmp.defaultLanguageDone
      ? DateTime.fromISO(tmp.defaultLanguageDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.nameOfMultipleAreasDone = tmp.nameOfMultipleAreasDone
      ? DateTime.fromISO(tmp.nameOfMultipleAreasDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.restaurantAddressDone = tmp.restaurantAddressDone
      ? DateTime.fromISO(tmp.restaurantAddressDone).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.userClickedThePayButton = tmp.userClickedThePayButton
      ? DateTime.fromISO(tmp.userClickedThePayButton).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;

    tmp.wantsMultipleLanguages = tmp.wantsMultipleLanguages
      ? DateTime.fromISO(tmp.wantsMultipleLanguages).toUTC(0, {
          keepLocalTime: true,
        })
      : null;
    return tmp;
  }

  toPersistence(concrete: IOnboardingProcess): any {}
}
