<app-stepper-page-template
  [title]="'restaurantDetails.onboarding.title'"
  [description]="'restaurantDetails.onboarding.description' | translate"
  [progressTitle]="'restaurantDetails.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="2"
  [image]="'assets/tables.png'"
  [imageAlt]="'restaurantDetaisl.onboarding.imageAlt'"
>
  <app-onboarding-restaurant-editor></app-onboarding-restaurant-editor>
</app-stepper-page-template>
