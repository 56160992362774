import { SupabaseService } from '@app/shared/supabase.service';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const authenticationGuardFn: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const supabase: SupabaseService = inject(SupabaseService);

  const skipCheckOnThesePaths = ['/login-with-password'];

  if (skipCheckOnThesePaths.includes(window.location.pathname)) {
    return true;
  }

  return await supabase.getSession2().then((session) => {
    console.debug('canActivate, User is authenticated', session);
    if (session) {
      return true;
    }

    console.debug(
      'canActivate - Not authenticated, redirecting and adding redirect url...redirect: ',
      state.url
    );
    router.navigate(['/login'], {
      queryParams: { redirect: state.url },
      replaceUrl: true,
    });
    return false;
  });
};
