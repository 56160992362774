<div class="login-container p-4">
  <app-stepper-page-template
    [title]="'login.loginWithEmailAndPassword'"
    [description]="'login.loginWithEmailAndPassword'"
    [showStep]="false"
    [progressTitle]="'login.loginWithEmailAndPassword'"
    [image]="'assets/logo.svg'"
    [imageAlt]="'magic.link.login.imageAlt'"
  >
    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
      <div class="login-error text-red-500" [hidden]="!error || isLoading">
        <span translate>login.usernameOrPasswordIncorrect</span>
      </div>
      <br />
      <div class="login-fields flex flex-col">
        <div class="inputWrapper mb-4">
          <label class="label">
            <span class="label-text" translate>login.email</span>
          </label>
          <input
            type="text"
            formControlName="email"
            autocomplete="email"
            placeholder="Email"
            class="input input-bordered w-full"
            required
          />
          <div
            *ngIf="
              loginForm.controls['email'].invalid &&
              loginForm.controls['email'].touched
            "
            class="text-red-500"
          >
            <span translate>login.emailRequired</span>
          </div>
        </div>
        <div class="inputWrapper mb-4">
          <label class="label">
            <span class="label-text" translate>login.password</span>
          </label>
          <input
            type="password"
            formControlName="password"
            autocomplete="password"
            placeholder="Password"
            class="input input-bordered w-full"
            required
          />
          <div
            *ngIf="
              loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched
            "
            class="text-red-500"
          >
            <span translate>login.passwordRequired</span>
          </div>
        </div>
        <div class="form-actions flex items-center justify-between mb-4">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
          >
            <span *ngIf="!isLoading" translate>login.login</span>
            <app-loader
              [isLoading]="isLoading"
              class="inline-loader"
            ></app-loader>
          </button>
        </div>
        <div *ngIf="userCredentialsIsWrong" class="text-red-500">
          <span translate>login.usernameOrPasswordIncorrectForgetPassword</span>
        </div>
        <a [href]="'/forgot-password'" class="text-blue-500" translate
          >login.forgotPassword</a
        >
      </div>
    </form>

    <div class="mt-8">
      <a [href]="'/'" class="text-blue-500" translate>login.createaccount</a>
    </div>
  </app-stepper-page-template>
</div>
