<div class="login-container p-4">
  <app-stepper-page-template
    [title]="'magic.link.login.verifytoken'"
    [description]="'magic.link.login.tokendesc'"
    [showStep]="false"
    [progressTitle]="'Signup'"
    [image]="'assets/logo.svg'"
    [imageAlt]="'magic.link.login.imageAlt'"
  >
    <form (ngSubmit)="verify()" [formGroup]="form" novalidate>
      <div class="login-fields flex flex-col">
        <div class="inputWrapper mb-4">
          <label class="label">
            <span class="label-text" translate>magic.link.login.token</span>
          </label>
          <input
            type="text"
            formControlName="token"
            class="input input-bordered w-full"
            required
          />
        </div>

        <div class="form-actions flex items-center justify-between mb-4">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="form.invalid || isLoading"
          >
            <span *ngIf="!isLoading" translate
              >magic.link.login.confirmToken</span
            >
            <app-loader
              [isLoading]="isLoading"
              class="inline-loader"
            ></app-loader>
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="tokenError" class="error" translate>
      magic.link.login.tokenError
    </div>

    <a [href]="'/'" class="text-blue-500" translate> magic.link.login.retry </a>
  </app-stepper-page-template>
</div>
<div class="gradientElement"></div>
