import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { IRestaurant } from '@app/shared/SupabaseTypes/IRestaurant';

export class RestaurantMapper implements Mapper<IRestaurant> {
  toDomain(raw: any): IRestaurant {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    return tmp;
  }

  toPersistence(concrete: IRestaurant): any {}
}
