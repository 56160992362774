import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { SupabaseService } from '@app/shared/supabase.service';
import { AuthenticationService } from '@app/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login-with-password.component.html',
  styleUrls: ['./login-with-password.component.scss'],
})
export class LoginWithPasswordComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  session: any;
  userCredentialsIsWrong = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private readonly supabase: SupabaseService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.supabase.authChanges((_, session) => {
      this.session = session;
      return this.session;
    });

    if (await this.authenticationService.isLoggedIn()) {
      this.router.navigate(
        [this.route.snapshot.queryParams['redirect'] || '/'],
        { replaceUrl: true }
      );
    }
  }

  login() {
    this.isLoading = true;
    try {
      this.supabase
        .signInWithPassword(
          this.loginForm.value.email,
          this.loginForm.value.password
        )
        .then((res) => {
          const userCredentialsIsWrong = res.error;
          if (userCredentialsIsWrong) {
            this.userCredentialsIsWrong = true;
            this.isLoading = false;
            return;
          }
          // TODO send brukeren til innlogget side her
          this.router.navigate(
            [this.route.snapshot.queryParams['redirect'] || '/'],
            { replaceUrl: true }
          );
        });
    } catch (error) {
      // Dette skal vel aldri skje?
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
}
