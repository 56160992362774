// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  staging: true,
  version: env['npm_package_version'] + '-staging',
  serverUrl: 'https://api.chucknorris.io',
  defaultLanguage: 'en',
  supportedLanguages: ['de', 'en'],
  finalOnboardingStep: 'onboardingCompleted',
  supabaseUrl: 'https://kfficefinzpnlvyshfgs.supabase.co',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtmZmljZWZpbnpwbmx2eXNoZmdzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ2NzIxNDksImV4cCI6MjA0MDI0ODE0OX0.rbC08yWDG_3kGrEYAyNIi00F3gaDlCo60bySRw8Smoo',
};
