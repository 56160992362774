<app-stepper-page-template
  [title]="'onboarding.activateConfirmationEmail.title'"
  [description]="'onboarding.activateConfirmationEmail.stepDescription'"
  [progressTitle]="'onboarding.activateConfirmationEmail.progressTitle'"
  [numberOfSteps]="7"
  [step]="6"
>
  <p class="mt-5" translate>onboarding.activateConfirmationEmail.p1</p>

  <div class="flex flex-row justify-center">
    <div class="mt-7 flex flex-row flex-wrap w-full justify-center gap-5">
      <button
        class="btn btn-primary btn-outline min-w-36"
        (click)="no()"
        translate
      >
        general.no
      </button>
      <button class="btn btn-primary min-w-36" (click)="yes()" translate>
        general.yes
      </button>
    </div>

    <div>
      <img
        style="max-height: 700px"
        src="assets/reservation-confirmation.png"
        alt="Booking Confirmation Email Example"
      />
    </div>
  </div>
</app-stepper-page-template>
