<app-stepper-page-template
  [title]="'onboarding.restaurant.title'"
  [description]="'onboarding.restaurant.p1' | translate"
  [progressTitle]="'restaurantDetails.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="2"
  [image]="'assets/mappin2.jpeg'"
  [imageAlt]="'restaurantDetaisl.onboarding.imageAlt'"
>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-control">
      <label class="label">
        <span class="label-text" translate>onboarding.restaurant.address</span>
      </label>
      <input
        type="text"
        class="input input-bordered"
        formControlName="address"
        autocomplete="address-line1"
        required
      />
      <div
        class="form-control-error"
        *ngIf="
          form.controls['address'].invalid && form.controls['address'].touched
        "
      >
        <span translate>required</span>
      </div>
    </div>
    <div class="form-control">
      <label class="label">
        <span class="label-text" translate>onboarding.restaurant.city</span>
      </label>
      <input
        type="text"
        class="input input-bordered"
        formControlName="city"
        autocomplete="address-line2"
        required
      />
      <div
        class="form-control-error"
        *ngIf="form.controls['city'].invalid && form.controls['city'].touched"
      >
        <span translate>required</span>
      </div>
    </div>
    <div class="form-control">
      <label class="label">
        <span class="label-text" translate>onboarding.restaurant.country</span>
      </label>
      <select formControlName="country" class="select select-bordered">
        <option *ngFor="let l of countrylist" [ngValue]="l">
          {{ l }}
        </option>
      </select>
    </div>

    <div class="flex flex-row flex-wrap w-full justify-between mt-7">
      <button
        (click)="back()"
        class="btn btn-primary btn-outline"
        type="button"
        translate
      >
        general.back
      </button>
      <button class="btn btn-primary" type="submit">
        <span translate>general.next</span>
      </button>
    </div>
  </form>
</app-stepper-page-template>
