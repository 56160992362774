import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SupabaseService } from '@app/shared/supabase.service';
import { ILanguage } from '@app/shared/SupabaseTypes/ILanguage';
import { ITranslation } from '@app/shared/SupabaseTypes/ITranslation';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@app/shared/translation.service';
import { AuthenticationService } from '@app/auth';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-translation-dialog',
  templateUrl: './translation-dialog.component.html',
  styleUrls: ['./translation-dialog.component.scss'],
})
export class TranslationDialogComponent implements OnInit {
  textId: number | undefined;
  language: string = '';
  text: string = '';
  description: string = '';

  languagesInUseByTenant!: ILanguage[];
  translations: ITranslation[] = [];
  form = new FormGroup({
    id: new FormControl(undefined),
    languages: new FormGroup({}),
  });
  defaultLanguage: string = '';
  onboardingIsCompleted: boolean = false;
  isTextArea: boolean = false;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(
    private dialogRef: DialogRef<TranslationDialogComponent>,
    @Inject(DIALOG_DATA)
    public data: ITranslation[],
    private supabase: SupabaseService,
    private translationService: TranslationService
  ) {}

  async ngOnInit(): Promise<void> {
    console.debug('Translation dialog input: ', this.data);

    this.defaultLanguage = await this.translationService.getDefaultLanguage();

    let defaultTranslation = this.data.find(
      (t) => t.language === this.defaultLanguage
    );
    this.description = defaultTranslation?.description || '';
    this.text = defaultTranslation?.text || '';
    this.textId = defaultTranslation?.textId || undefined;
    this.isTextArea = defaultTranslation?.typeOfInput === 'textarea';

    this.languagesInUseByTenant = await this.supabase.getTenantLanguages();
    this.languagesInUseByTenant = this.sortDefaultLanguageOnTop(
      this.languagesInUseByTenant
    );

    console.log('this.languagesInUseByTenant', this.languagesInUseByTenant);
    this.languagesInUseByTenant.forEach((l) => {
      this.languagesFormArray.addControl(l.id, new FormControl(), {});
    });

    this.translations = this.data;

    this.buildLanguagesFormArray();

    this.onboardingIsCompleted = !window.location.href.includes('onboarding');
  }

  buildLanguagesFormArray() {
    this.translations.forEach((t) => {
      if (
        t.language &&
        typeof this.languagesFormArray.controls[t.language] !== 'undefined'
      ) {
        this.languagesFormArray.controls[t.language].setValue(t.text);
      }
    });
  }

  get languagesFormArray(): any {
    return this.form.get('languages') as FormGroup;
  }
  async onSubmit() {
    let values = this.form.getRawValue();
    console.debug('Translation dialog: values onSubmit()', values);

    const translations: ITranslation[] = [];
    Object.entries<string>(values.languages).forEach(([key, value]) => {
      const translationTextIsSet = value !== null;
      if (translationTextIsSet) {
        const translation: ITranslation = {
          language: key,
          text: value,
          description: this.description,
        };
        if (this.textId) {
          translation.textId = this.textId;
        }

        if (this.isTextArea) {
          translation.typeOfInput = 'textarea';
        }

        translation.id = this.translations.find((t) => t.language === key)?.id;

        translations.push(translation);
      }
    });

    // @ts-ignore dialogRef skulle muligens her vært <ITranslation[]> og hatt dialogComponenten i parantes ala dialogRef<ITranslation[]>(TranslationsCompontent)
    this.dialogRef.close(translations);
  }

  close() {
    const noTranslations: ITranslation[] = [];

    // @ts-ignore dialogRef skulle muligens her vært
    this.dialogRef.close(noTranslations);
  }

  getExistingTranslation(language: string): ITranslation | undefined {
    return this.translations.find((t) => t.language === language);
  }

  async translateAllTexts() {
    console.log('iTranslations', this.text);
    for (const l of this.languagesInUseByTenant) {
      if (l.id === this.defaultLanguage) {
        // ikke oversett standardspråket
        continue;
      }
      let translated = await this.translationService.machineTranslation(
        this.text,
        this.language,
        l.id,
        this.description
      );
      this.languagesFormArray.controls[l.id].setValue(translated);
    }
  }

  private sortDefaultLanguageOnTop(languages: ILanguage[]): ILanguage[] {
    return languages.sort((a, b) => {
      if (a.id === this.defaultLanguage) {
        return -1;
      } else if (b.id === this.defaultLanguage) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
