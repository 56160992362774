import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { onboardingRouteSteps } from '../onboarding.service';
import { Location } from '@angular/common';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';

import { languages, TLanguages } from 'countries-list';
import { ITranslation } from '@app/shared/SupabaseTypes/ITranslation';
import { ITenant } from '@app/shared/SupabaseTypes/ITenant';
import { ILanguage } from '@app/shared/SupabaseTypes/ILanguage';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-number-of-areas',
  templateUrl: './onboarding-add-language.component.html',
  styleUrls: ['./onboarding-add-language.component.scss'],
})
export class OnboardingAddLanguageComponent implements OnInit {
  form = new FormGroup({
    language: new FormControl(''),
  });
  languages: ILanguage[] = [];
  translationsFromDb: ITranslation[] = [];
  tenantData!: ITenant;
  languagesInUseByTenant: ILanguage[] = [];
  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.tenantData = await this.supabase.getTenant();
    this.languages = await this.supabase.getLanguages();
    this.languagesInUseByTenant = await this.supabase.getTenantLanguages();

    this.languages = this.languages
      .filter((v) => v.id !== this.tenantData.defaultLanguage)
      .sort((a, b) => a.value.localeCompare(b.value));
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step51]);
  }

  async onSubmit() {
    let language = this.form.value.language;

    if (typeof language === 'string') {
      await this.supabase.addLanguage(language).then(async () => {
        this.form.setValue({ language: null });
        this.languagesInUseByTenant = await this.supabase.getTenantLanguages();
      });

      const toastMessageSuccess = this.translateService.instant(
        'account.addRemoveLanguages.languageAdded'
      );
      this.toastr.info(toastMessageSuccess);
    }
  }

  async next() {
    await this.supabase.updateUsersProgressInOnboardingProcess(
      'addMultipleLanguagesDone'
    );
    this.router.navigate([onboardingRouteSteps.step200]);
  }

  get defaultLanguageAsText() {
    return this.languages.find((l) => l.id === this.tenantData.defaultLanguage)
      ?.value;
  }

  async delete(languageId: string) {
    await this.supabase.removeLanguage(languageId).then(async () => {
      this.languagesInUseByTenant = await this.supabase.getTenantLanguages();
    });
  }
}
