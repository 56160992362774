<div class="success page">
  <div class="success-content">
    <h1 translate class="heading1">onboarding.successTitle</h1>
    <h2 translate class="heading2">onboarding.successSubtitle</h2>

    <div class="max-w-lg flex flex-col gap-y-5">
      <p translate class="info">onboarding.whatsNextDescription</p>
    </div>

    <button
      [disabled]="isLoading"
      class="btn btn-secondary mt-7"
      (click)="createBooking()"
      translate
    >
      <span *ngIf="!isLoading" translate>onboarding.successButton</span>
      <app-loader
        [isLoading]="isLoading"
        *ngIf="isLoading"
        class="inline-loader"
      ></app-loader>
    </button>
  </div>
</div>
