import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor() {}

  getSmsCreditsLink(userEmail: string, userLanguage: string, tenantId: string) {
    let stripeLink = '';
    if (window.location.href.includes('staging')) {
      stripeLink = 'https://buy.stripe.com/test_4gw5l5bhA31r73q7sv';
    } else if (window.location.href.includes('localhost')) {
      stripeLink = 'https://buy.stripe.com/test_dR63cX85o31r3Re8wA';
    } else {
      let stripeLink = 'https://buy.stripe.com/cN215L3hYgUS65O6oo';
    }
    return `${stripeLink}?prefilled_email=${encodeURIComponent(
      userEmail
    )}&locale=${userLanguage}&client_reference_id=${tenantId}`;
  }

  getBillingUrl(customerEmail: string) {
    return (
      'https://billing.stripe.com/p/login/test_5kAcPlcuLcDycnu4gg?prefilled_email=' +
      encodeURIComponent(customerEmail)
    );
  }
}
