import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      loadChildren: () =>
        import('./bookings/bookings.module').then((m) => m.BookingsModule),
    },
    {
      path: 'my-account',
      loadChildren: () =>
        import('./my-account/my-account.module').then((m) => m.MyAccountModule),
    },
    {
      path: 'restaurants',
      loadChildren: () =>
        import('./restaurants/restaurants.module').then(
          (m) => m.RestaurantsModule
        ),
    },
    {
      path: 'customers',
      loadChildren: () =>
        import('./customers/customer.module').then((m) => m.CustomerModule),
    },
    {
      path: 'communication-settings',
      loadChildren: () =>
        import('./communication-settings/communication-settings.module').then(
          (m) => m.CommunicationSettingsModule
        ),
    },
    {
      path: 'translation-settings',
      loadChildren: () =>
        import('./translation-settings/translation-settings.module').then(
          (m) => m.TranslationSettingsModule
        ),
    },
    {
      path: 'statistics',
      loadChildren: () =>
        import('./statistics/statistics.module').then(
          (m) => m.StatisticsModule
        ),
    },
    {
      path: 'widget',
      loadChildren: () =>
        import('./widget/widget.module').then((m) => m.WidgetModule),
    },
    {
      path: 'users',
      loadChildren: () =>
        import('./users/users.module').then((m) => m.UsersModule),
    },
    { path: '**', redirectTo: '/overview', pathMatch: 'full' },
  ]),
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
