import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupabaseService } from '@app/shared/supabase.service';
import { environment } from '@env/environment';
import { ConfettiService } from '@app/shared/confetti.service';

@Component({
  selector: 'app-onboarding-success',
  templateUrl: './onboarding-success.component.html',
  styleUrls: ['./onboarding-success.component.scss'],
})
export class OnboardingSuccessComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private confetti: ConfettiService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.supabase.updateUsersProgressInOnboardingProcess(
      'onboardingCompleted'
    );
  }

  ngAfterViewInit(): void {
    this.confetti.shoot();
  }

  // Currently just makes the onboarding completed
  async createBooking() {
    this.router.navigate(['../overview']);
  }
}
