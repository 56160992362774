import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { ILanguage } from '@app/shared/SupabaseTypes/ILanguage';

export class LanguageMapper implements Mapper<ILanguage> {
  toDomain(raw: any): ILanguage {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    return tmp;
  }

  toPersistence(concrete: ILanguage): any {}
}
