<app-stepper-page-template
  [title]="'openingHours.onboarding.title'"
  [description]="'openingHours.onboarding.stepDescription'"
  [progressTitle]="'openingHours.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="3"
>
  <p class="mt-3" translate>openingHours.onboarding.description</p>
  <p class="mt-3" translate>openingHours.onboarding.description2</p>

  <button
    class="btn btn-primary min-w-36 mt-6"
    (click)="newGeneralOpeningHours()"
  >
    <span translate>openingHours.newOpeningHours</span>
  </button>

  <div class="opening-hours-table" *ngIf="openingHours.length > 0">
    <table class="table table-auto w-full mt-4">
      <thead>
        <tr>
          <th translate>general.weekday</th>
          <th translate>general.opens</th>
          <th translate>general.closes</th>
          <th class="hidden sm:table-cell" translate>general.bookingCutOff</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of openingHours">
          <td class="sm:hidden" translate>
            {{ getWeekDay(element.weekday, "short").toString() }}
          </td>
          <td class="hidden sm:table-cell" translate>
            {{ getWeekDay(element.weekday, "long").toString() }}
          </td>
          <td>{{ element.opensString }}</td>
          <td>{{ element.closesString }}</td>
          <td class="hidden sm:table-cell">
            <ng-container *ngIf="element.bookingClosesMinutesBefore > 0">
              {{ element.bookingClosesMinutesBefore }}
              <span translate>general.general.bookingCutOff</span>
            </ng-container>
            <ng-container
              *ngIf="
                element.bookingClosesMinutesBefore <= 0 ||
                !element.bookingClosesMinutesBefore
              "
            >
              <span translate>No cut-off</span>
            </ng-container>
          </td>
          <td>
            <div class="flex flex-row gap-x-2">
              <button
                class="btn btn-outline"
                (click)="editOpeningHour(element)"
                aria-label="Edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </button>
              <button
                class="btn btn-outline btn-error"
                (click)="deleteOpeningHour(element.id)"
                aria-label="Delete"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-6 flex flex-row flex-wrap w-full justify-between">
    <button class="btn btn-outline min-w-36" (click)="back()" translate>
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
