import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { environment } from '@env/environment';
import { SupabaseService } from '@app/shared/supabase.service';
import { AuthenticationService } from '@app/auth';

@Component({
  selector: 'app-login',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.scss'],
})
export class VerifyTokenComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  form = new FormGroup({
    email: new FormControl(''),
    token: new FormControl(''),
  });
  isLoading = false;

  session: any;
  showRetryInformation = false;
  tokenError: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private readonly supabase: SupabaseService
  ) {}

  async ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.form.patchValue({
        email: params.get('email'),
      });
    });

    this.supabase.authChanges((_, session) => {
      this.session = session;
      return this.session;
    });

    if (await this.authenticationService.isLoggedIn()) {
      this.router.navigate(
        [this.route.snapshot.queryParams['redirect'] || '/overview'],
        { replaceUrl: true }
      );
    }
  }

  verify() {
    let rawValue = this.form.getRawValue();

    if (rawValue.email && rawValue.token) {
      this.verifyEmail(rawValue.email, rawValue.token);
    }
  }

  verifyEmail(email: string, tokenFromEmail: string) {
    this.supabase
      .verifyTokenReceivedByEmail(email, tokenFromEmail)
      .then((res) => {
        if (res.data && !res.error) {
          this.router.navigate(['/overview'], { replaceUrl: true });
        } else {
          this.tokenError = true;
        }
      });
  }
}
