import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { ITable } from '@app/shared/SupabaseTypes/ITable';

/**
 * Pretty much a duplicate of tables-view. But uses
 * input instead of params to fetch the data.
 * I think we can delete the other one later.
 */
@Component({
  selector: 'app-area-tables-view',
  templateUrl: './area-tables-view.component.html',
  styleUrls: ['./area-tables-view.component.scss'],
})
export class AreaTablesViewComponent {
  displayedColumns: string[] = [
    'name',
    'capacityFrom',
    'capacityTo',
    'bookingPriority',
    'shape',
    'editButton',
    'deleteButton',
  ];
  @Input() tables: ITable[] = [];
  @Output() deleteTable: EventEmitter<ITable> = new EventEmitter<ITable>();
  @Output() editTable: EventEmitter<ITable> = new EventEmitter<ITable>();

  constructor() {}

  onEdit(table: ITable) {
    this.editTable.emit(table);
  }

  onDelete(table: ITable) {
    this.deleteTable.emit(table);
  }
}
