import { Component, OnInit } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { ITable } from '@app/shared/SupabaseTypes/ITable';
import { DeleteAlertComponent } from '@app/shared/components/delete-alert/delete-alert.component';
import { InfoAlertComponent } from '@app/shared/components/info-alert/info-alert.component';
import { SupabaseService } from '@app/shared/supabase.service';
import { AreaCreatorComponentDialog } from '@app/shared/components/area-creator-dialog/area-creator-dialog.component';
import { TableEditorDialogComponent } from '@app/shared/components/table-editor-dialog/table-editor-dialog.component';
import { onboardingRouteSteps } from '../onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

type AreasWithTables = IArea & { tables: ITable[] };

/**
 * Note!
 * I had two approaches. One was to refetch all tables and areas on each change,
 * this would make sure the view the user see is 100% up to date..
 * It would also make the code much cleaner.
 *
 * The other approach was just to to the post requests and keep the
 * state locally. This make it more snappy, but can result in the data
 * not matching the database if error is not handled well enough.
 *
 * It is also a bit more code. But i went for this approach for now to avoid
 * sending to many requests to the database.
 */
@Component({
  selector: 'app-onboarding-tables-and-areas',
  templateUrl: './onboarding-tables-and-areas.component.html',
  styleUrls: ['./onboarding-tables-and-areas.component.scss'],
})
export class OnboardingTablesAndAreasComponent implements OnInit {
  areas: AreasWithTables[] = [];
  restaurantId: number = -1;
  isLoading = false;

  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public dialog: Dialog,
    private location: Location
  ) {}

  ngOnInit(): void {
    const paramRestaurantId = this.route.snapshot.paramMap.get('restaurant-id');
    this.restaurantId = paramRestaurantId ? +paramRestaurantId : -1;
    if (this.restaurantId > 0) {
      this.getAreasAndTables(this.restaurantId);
    } else {
      this.router.navigate([onboardingRouteSteps.step3]);
    }
  }

  newArea() {
    let dialogRef = this.dialog.open(AreaCreatorComponentDialog, {
      autoFocus: '__non_existing_element__',
      data: { restaurantId: this.restaurantId },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        const newData = result;
        this.areas.push(newData);
        console.info('Created area successfully');
      }
    });
  }

  editArea(area: AreasWithTables) {
    let dialogRef = this.dialog.open(AreaCreatorComponentDialog, {
      autoFocus: '__non_existing_element__',
      data: { restaurantId: this.restaurantId, areaData: area },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        // find item in array and replace it, but not tables
        const updatedData = result;
        const index = this.areas.findIndex((a) => a.id === updatedData.id);
        this.areas[index] = {
          ...updatedData,
          tables: this.areas[index].tables,
        };
        console.info('Updated area successfully');
      }
    });
  }

  deleteArea(area: AreasWithTables) {
    let dialogRef = this.dialog.open(DeleteAlertComponent, {
      data: { name: `${area?.name}` ?? '' },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.supabase.deleteArea(area.id).then((result) => {
          if (result && !result?.error) {
            this.areas = this.areas.filter((a) => a.id !== area.id);
          }
          if (result?.error) {
            this.dialog.open(InfoAlertComponent, {
              data: {
                title: 'Error',
                description:
                  'You have to delete all tables before you can delete an area',
              },
            });
          }
        });
      }
    });
  }

  newTable(area: AreasWithTables) {
    let suggestedTableName = '';
    if (
      area.tables &&
      area.tables.length > 0 &&
      typeof +area.tables[0].name === 'number'
    ) {
      suggestedTableName = +area.tables[area.tables.length - 1].name + 1 + '';
    }
    let dialogRef = this.dialog.open(TableEditorDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: {
        restaurantId: this.restaurantId,
        areaData: area,
        tableData: undefined,
        tableNumberSuggestion: suggestedTableName,
      },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        // replace the tables in the area with the result and make sure the child component notice the change
        const newData = result.data;
        const index = this.areas.findIndex((a) => a.id === area.id);
        this.areas[index] = {
          ...area,
          tables: [...(area.tables ?? []), newData],
        };
        console.info(
          `Created table ${newData.id} successfully in area ${area.id}`
        );
      }
    });
  }

  editTable(table: ITable, area: AreasWithTables) {
    let dialogRef = this.dialog.open(TableEditorDialogComponent, {
      autoFocus: '__non_existing_element__',
      data: {
        restaurantId: this.restaurantId,
        areaData: area,
        tableData: table,
      },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        // replace the tables in the area with the result and make sure the child component notice the change
        const updatedData = result.data;
        const index = this.areas.findIndex((a) => a.id === area.id);
        const tableIndex = this.areas[index].tables.findIndex(
          (t) => t.id === updatedData.id
        );
        this.areas[index].tables[tableIndex] = updatedData;
        this.areas[index] = {
          ...area,
          tables: [...this.areas[index].tables],
        };
        console.info(
          `Updated table ${table.id} successfully in area ${area.id}`
        );
      }
    });
  }

  deleteTable(table: ITable, area: AreasWithTables) {
    let dialogRef = this.dialog.open(DeleteAlertComponent, {
      data: { name: `table ${table.name}` ?? '' },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.supabase.deleteTable(table.id).then((result) => {
          if (result && !result?.error) {
            console.info(
              `Deleted table ${table.id} successfully from area ${area.id}`
            );
            area.tables = area.tables.filter((t) => t.id !== table.id);
          }
          if (result?.error) {
            console.error(
              `Failed to delete table ${table.id} from area ${area.id}`,
              result?.error
            );
          }
        });
      }
    });
  }

  back() {
    this.router.navigate([onboardingRouteSteps.step3, this.restaurantId]);
  }

  // check if we have tables or not. If we do, we can go to the next step
  next() {
    this.isLoading = true;
    this.getAreasAndTables(this.restaurantId)
      .then((result) => {
        let hasAnyTables = false;
        this.areas.forEach((area) => {
          if (area.tables.length > 0) {
            hasAnyTables = true;
            return;
          }
        });
        if (hasAnyTables) {
          this.isLoading = false;
          this.router.navigate([onboardingRouteSteps.step5]);
        } else {
          this.isLoading = false;
          this.dialog.open(InfoAlertComponent, {
            data: {
              title: 'tableAndAreas.error.noTablesTitle.restaurant',
              description:
                'tableAndAreas.error.tableAndAreasNoTablesDescription',
            },
          });
        }
      })
      .catch((error) => {
        console.error('Failed to fetch areas and tables', error);
      });
  }

  private async getTablesForArea(area: IArea): Promise<AreasWithTables> {
    const tables = await this.supabase.getTablesByAreaId(area.id);
    return {
      ...area,
      tables: tables,
    };
  }

  private async getAreasAndTables(
    restaurantId: number
  ): Promise<AreasWithTables[]> {
    // First fetch all the areas without tables.
    const areasWithoutTables = await this.supabase
      .getAreas(restaurantId)
      .then((getAreasResult: IArea[]) => {
        const areas: AreasWithTables[] = getAreasResult.map((area) => {
          return {
            ...area,
            tables: [],
          };
        });
        return areas;
      });
    const areasWithTables = await Promise.all(
      areasWithoutTables.map(async (area) => {
        return await this.getTablesForArea(area);
      })
    );
    this.areas = areasWithTables;
    return this.areas;
  }
}
