import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupabaseService } from '@app/shared/supabase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { onboardingRouteSteps } from '@app/onboarding/onboarding.service';
import { IRestaurant } from '@app/shared/SupabaseTypes/IRestaurant';
import { HttpClient } from '@angular/common/http';
import { getCountryData } from 'countries-list';
import { countries } from 'countries-list';

@Component({
  selector: 'app-onboarding-restaurant',
  templateUrl: './onboarding-restaurant-address-city-country.component.html',
  styleUrls: ['./onboarding-restaurant-address-city-country.component.scss'],
})
export class OnboardingRestaurantAddressCityCountryComponent implements OnInit {
  form = new FormGroup({
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
  });
  private restaurant: any;
  restaurantId = -1;
  countrylist: string[] = [];

  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    for (let countriesKey in countries) {
      this.countrylist.push(countries[countriesKey].name);
    }
    const paramId = this.route.snapshot.paramMap.get('restaurant-id');
    this.restaurantId = paramId ? +paramId : -1;

    this.supabase.getRestaurants().then((restaurantData: IRestaurant[]) => {
      if (this.restaurantId > -1) {
        this.restaurant = restaurantData.find(
          (t) => t.id === this.restaurantId
        );
      } else {
        this.restaurant = restaurantData.length > 0 ? restaurantData[0] : null;
      }

      if (this.restaurant) {
        this.form.get('address')?.setValue(this.restaurant.address);
        this.form.get('city')?.setValue(this.restaurant.city);
        this.form.get('country')?.setValue(this.restaurant.country);
      }

      const noLocationData =
        !this.restaurant?.address &&
        !this.restaurant?.city &&
        !this.restaurant?.country;
      if (noLocationData) {
        this.getLocationSuggestion(this.restaurant);
      }
    });
  }

  async onSubmit() {
    let values = this.form.getRawValue();
    if (this.form.valid && values.address && values.city && values.country) {
      try {
        const x = await this.supabase.upsertRestaurantLocation(
          this.restaurant?.id,
          values.address,
          values.city,
          values.country
        );
        await this.supabase.updateUsersProgressInOnboardingProcess(
          'restaurantAddressDone'
        );

        const restaurantWasModified = this.restaurant?.id; // ie it existed from before
        if (restaurantWasModified) {
          this.router.navigate([
            onboardingRouteSteps.step3,
            this.restaurant.id,
          ]);
        } else {
          this.router.navigate([onboardingRouteSteps.step3, x.data.id]);
        }
      } catch (error) {
        console.error('Error updating restaurant location', error);
      }
    }
  }

  public back(): void {
    this.router.navigate([onboardingRouteSteps.step2]);
  }

  private getLocationSuggestion(restaurant: IRestaurant) {
    if (restaurant) {
      this.getCountry().subscribe((data) => {
        // @ts-ignore
        const countryData = getCountryData(data.country);
        this.form.get('country')?.setValue(countryData.name);
      });
    }
  }

  // bør bytte ut denne med mitt eget endepunkt tilfelle de går ned
  private getCountry() {
    return this.http.get('https://api.country.is', {
      responseType: 'json',
    });
  }
}
