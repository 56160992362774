<app-stepper-page-template
  [title]="'numberOfAreas.onboarding.title'"
  [description]="'numberOfAreas.onboarding.stepDescription'"
  [progressTitle]="'numberOfAreas.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="4"
>
  <form [formGroup]="form" class="flex flex-row justify-center mt-10 mb-10">
    <div>
      <button class="btn btn-circle" (click)="changeAreas(-1)" type="button">
        -
      </button>

      <input
        id="areas"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        class="input"
        style="width: 70px; font-size: 40px; text-align: center"
        formControlName="areas"
        required
      />

      <button class="btn btn-circle" (click)="changeAreas(1)" type="button">
        +
      </button>
    </div>
  </form>

  <div class="mt-6 flex flex-row flex-wrap w-full justify-between">
    <button
      class="btn btn-primary btn-outline min-w-36"
      (click)="back()"
      translate
    >
      general.back
    </button>
    <button class="btn btn-primary min-w-36" (click)="next()" translate>
      general.next
    </button>
  </div>
</app-stepper-page-template>
