<form [formGroup]="tableForm" (ngSubmit)="onSubmit()" class="">
  <div *ngIf="!table">
    <h2 class="mb-10 text-center" translate>
      <span translate>tableCreator.addTableTo.restaurant</span>
      {{ area.name }}
    </h2>
  </div>
  <div *ngIf="table">
    <h2 class="mb-10 text-center" translate>
      <span translate>tableCreator.editTable.restaurant</span>
      {{ table.name }}
    </h2>
  </div>

  <div class="flex flex-row justify-between flex-wrap gap-4">
    <label class="form-control max-w-xs">
      <div class="label">
        <span class="label-text" translate>tableCreator.tableNumber</span>
      </div>
      <input
        formControlName="name"
        type="text"
        required
        [placeholder]="'tableCreator.tableNumber.placeholder' | translate"
        class="input input-bordered w-full max-w-xs"
      />
    </label>

    <label class="form-control max-w-xs">
      <div class="label">
        <span class="label-text" translate
          >tableCreator.minimumAllowedBookingSize</span
        >
      </div>
      <input
        formControlName="capacityFrom"
        type="text"
        required
        [placeholder]="
          'tableCreator.minimumAllowedBookingSize.placeholder' | translate
        "
        class="input input-bordered w-full max-w-xs"
      />
    </label>

    <label class="form-control max-w-xs">
      <div class="label">
        <span class="label-text" translate>tableCreator.maxCapacity</span>
      </div>
      <input
        formControlName="capacityTo"
        type="text"
        required
        [placeholder]="'tableCreator.maxCapacity.placeholder' | translate"
        class="input input-bordered w-full max-w-xs"
      />
    </label>

    <label class="form-control max-w-xs">
      <div class="label">
        <span class="label-text" translate>tableCreator.bookingPriority</span>
      </div>
      <input
        formControlName="bookingPriority"
        type="text"
        required
        [placeholder]="'tableCreator.bookingPriority.placeholder' | translate"
        class="input input-bordered w-full max-w-xs"
      />
    </label>

    <label class="form-control">
      <div class="label">
        <span class="label-text" translate>tableCreator.tableShape</span>
      </div>
      <select formControlName="shape" class="select select-bordered">
        <option *ngFor="let shape of tableShape" [ngValue]="shape" translate>
          {{ "tableShapes." + shape }}
        </option>
      </select>
    </label>
  </div>

  <div class="actions m-5">
    <button type="button" class="btn btn-outline" (click)="close()" translate>
      general.close
    </button>
    <button class="btn btn-primary" type="submit" translate>
      general.save
    </button>
  </div>
</form>
