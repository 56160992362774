import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime, Duration } from 'luxon';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';

export class OpeningHoursMapper implements Mapper<IOpeningHours> {
  constructor() {}

  toDomain(raw: any, hourFormatForDateTime: string): IOpeningHours {
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.activeFromDate = DateTime.fromISO(tmp.activeFromDate);

    tmp.activeToDate = DateTime.fromISO(tmp.activeToDate);

    tmp.opensDateTime = DateTime.now()
      .set({ hour: 0, minute: 0 })
      .plus({ minute: tmp.opens });

    tmp.opensString = tmp.opensDateTime.toFormat(hourFormatForDateTime);

    tmp.closesDateTime = DateTime.now()
      .set({ hour: 0, minute: 0 })
      .plus({ minute: tmp.closes });

    tmp.closesString = tmp.closesDateTime.toFormat(hourFormatForDateTime);

    return tmp;
  }

  toPersistence(concrete: IOpeningHours): any {}
}
