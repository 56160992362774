import { Routes, Route } from '@angular/router';

import { OnboardingComponent } from './onboarding.component';
import { authenticationGuardFn } from '@app/auth';

export const onboardingRouteSteps = {
  step1: 'onboarding/organization',
  step2: 'onboarding/restaurant',
  step21: 'onboarding/restaurant-address',
  step3: 'onboarding/opening-hours',
  step41: 'onboarding/number-of-areas',
  step42: 'onboarding/name-of-areas',
  step43: 'onboarding/tables-and-areas',
  step5: 'onboarding/languages',
  step51: 'onboarding/default-language',
  step52: 'onboarding/add-languages',
  step200: 'onboarding/booking-confirmation',
  step500: 'onboarding/subscribe',
  step1000: 'onboarding/success-screen',
};

/**
 * Provides helper methods to create routes.
 */
export class Onboarding {
  static childRoutes(routes: Routes): Route {
    return {
      path: 'onboarding',
      component: OnboardingComponent,
      children: routes,
      canActivate: [authenticationGuardFn],
    };
  }
}
